import http from "./httpService";
import axios from "axios";
import { apiUrl } from "../config.json";

export function getServiceTypes() {
  return http.get(apiUrl + "serviceProviders/serviceTypes");
}

export function saveServiceProvider(firstName, lastName, provider, profileImage, data, taxID, news_updates, zone_long, zone_lat) {
  const formData = new FormData();

  //selfEmployed = models.BooleanField()
  //taxID = models.CharField(max_length=255)

  /* 
    accessible_entry = models.BooleanField(default=False)
    langs = TaggableManager(through=LangsSegment, blank=True, related_name='lang')
    equipments = TaggableManager(through=EquipmentsSegment, blank=True, related_name='equipment')
  
    discoverable = models.BooleanField(default=True) */

  profileImage ? formData.append("profileImage", profileImage) : null;
  formData.append("provider", provider);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("streetAddress", data.streetAddress);
  formData.append("profile_business_street_address", data.streetAddress);
  formData.append("country", data.country);
  formData.append("state", data.state);
  formData.append("city", data.city);
  formData.append("postalCode", data.postalCode);
  formData.append("emailAddress", data.emailAddress);
  formData.append("phoneNo", data.phoneNo);
  formData.append("website", data.website);
  formData.append("serviceType", data.serviceType);
  formData.append("selfEmployed", 0);
  formData.append("businessName", data.businessName);
  formData.append("taxID", taxID);
  formData.append("news_updates", news_updates);
  formData.append("zone_long", zone_long);
  formData.append("zone_lat", zone_lat);
  formData.append("english_multilang", data.english_multilang ? 1 : 0);
  formData.append("service_24_7", data.service_24_7 ? 1 : 0);
  formData.append("accept_credit_card", data.accept_credit_card ? 1 : 0);
  formData.append("special_equipment", data.special_equipment ? 1 : 0);
  formData.append("deferred_payment", data.deferred_payment ? 1 : 0);
  formData.append("loyalty_program", data.loyalty_program ? 1 : 0);
  return axios.post(apiUrl + "serviceProviders/ServiceProviderAndProfile/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
 
}
export function saveVet(firstName, lastName, provider, profileImage, data, taxID, news_updates, zone_long, zone_lat) {
  const formData = new FormData();

  
  console.log(data.serviceType);
  profileImage ? formData.append("profileImage", profileImage) : null;
  formData.append("provider", provider);
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("streetAddress", data.streetAddress);
  formData.append("profile_business_street_address", data.streetAddress);
  formData.append("country", data.country);
  formData.append("state", data.state);
  formData.append("city", data.city);
  formData.append("postalCode", data.postalCode);
  formData.append("emailAddress", data.emailAddress);
  formData.append("phoneNo", data.phoneNo);
  formData.append("website", data.website);
  formData.append("practiceType", data.practiceType);
  formData.append("selfEmployed", 0);
  formData.append("practiceName", data.practiceName);
  formData.append("taxID", taxID);
  formData.append("news_updates", news_updates);
  formData.append("zone_long", zone_long);
  formData.append("zone_lat", zone_lat);
  formData.append("english_multilang", data.english_multilang ? 1 : 0);
  formData.append("service_24_7", data.service_24_7 ? 1 : 0);
  formData.append("accept_credit_card", data.accept_credit_card ? 1 : 0);
  formData.append("special_equipment", data.special_equipment ? 1 : 0);
  formData.append("deferred_payment", data.deferred_payment ? 1 : 0);
  formData.append("loyalty_program", data.loyalty_program ? 1 : 0);
  return axios.post(apiUrl + "veterinarians/veterinarianAndProfile/", formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  /* .then((response) => {
        // this.$router.push("AccountConfirmation");
      })
      .catch((error) => {
        console.log(error, error.response);
      }); */

  /*  .then((response) => {
        //this.$router.push("AccountConfirmation");
      })
      .catch((error) => {
        console.log(error, error.response);
      }); */
}

export function updateServiceProvider(profileImage, form, coordinates) {
  console.log(coordinates, "/////////");
  const formData = new FormData();
  profileImage ? formData.append("profileImage", profileImage) : null;
  formData.append("firstName", form.firstName);
  formData.append("lastName", form.firstName);
  formData.append("provider", "email");
  formData.append("news_updates", 0);
  formData.append("zone_long", coordinates.lng);
  formData.append("zone_lat", coordinates.lat);
  formData.append("streetAddress", form.streetAddress);
  formData.append("country", form.country);
  formData.append("state", form.state);
  formData.append("city", form.city);
  formData.append("postalCode", form.postalCode);
  formData.append("emailAddress", form.emailAddress);
  formData.append("phoneNo", form.phoneNo);
  formData.append("website", form.website);
  formData.append("serviceType", form.serviceType);
  formData.append("selfEmployed", form.selfEmployed);
  formData.append("businessName", form.businessName);
  formData.append("taxID", form.taxID);
  return axios.put(apiUrl + "serviceProviders/serviceProvider/" + form.emailAddress, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getSP(id) {
  return axios.get(apiUrl + "serviceProviders/serviceProvider/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getSPList() {
  return axios.get(apiUrl + "serviceProviders/serviceProvider/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function deleteserviceProvider(id) {
  return axios.delete(apiUrl + "serviceProviders/serviceProvider/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function saveSPGallery(data) {
  const formData = new FormData();
  formData.append("emailAddress", localStorage.getItem("email"));
  formData.append("serviceImage", data.serviceImage);

  return axios.post(apiUrl + "serviceProviders/serviceProviderGallery/", formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getSPGalleryByID(id) {
  return axios.get(apiUrl + "serviceProviders/serviceProviderGallery/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getSPGallery(id) {
  return axios.get(apiUrl + "serviceProviders/serviceProvider/" + id + "/gallery/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function deleteSPGallery(id) {
  return axios.delete(apiUrl + "serviceProviders/serviceProviderGallery/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function updateSPGallery(data) {
  const formData = new FormData();
  formData.append("emailAddress", localStorage.getItem("email"));
  formData.append("serviceImage", data.serviceImage);

  return axios.put(apiUrl + "serviceProviders/serviceProviderGallery/" + id, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

//----------------------------------- ServiceProviderWorkingHours ----------------

export function getSPWorkingHours() {
  return axios.get(apiUrl + "serviceProviders/ServiceProviderWorkingHours/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function saveSPWorkingHours(req) {
  /* const req = {
    emailAddress: localStorage.getItem("email"),
    day: data.day,
    working_start: data.working_start,
    working_finish: data.working_finish,
    break_start: data.break_start,
    break_finish: data.break_finish,
    enable: data.enable,
  }; */

  return axios.post(apiUrl + "serviceProviders/ServiceProviderWorkingHours/", req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function updateSPWorkingHours(data) {
  const req = {
    emailAddress: localStorage.getItem("email"),
    day: data.day,
    working_start: data.working_start,
    working_finish: data.working_finish,
    break_start: data.break_start,
    break_finish: data.break_finish,
    enable: data.enable,
  };

  return axios.put(apiUrl + "serviceProviders/ServiceProviderWorkingHours/", req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
