import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

let config = {
  headers: {
    'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
  },
};
export function sendRegistrationEmail(data) {
  const req = {
    email_address: data.email,
    lang: data.lang,
    role: data.role,
    servicePracticeType: data.servicePracticeType,
  };
  console.log('***********', req);
  if (data.referral != '') {
    req.referral = data.referral;
  }
  if (data.ruleID != '') {
    req.ruleID = data.ruleID;
  }
  console.log('***********', req);
  return axios.post(apiUrl + 'email/registration/sendRegistrationEmail', req);
}

export function sendLoginEmail(email, lang) {
  return axios.post(apiUrl + 'email/login/sendLoginEmail', {
    email_address: email,
    lang: lang,
  });
}

export function getEmailcheckRegistration(code) {
  return axios.get(apiUrl + 'email/registration/checkRegistrationEmail/' + code);
}
