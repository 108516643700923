import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

const apiEndpoint = apiUrl + 'panicButton/';

export function reportPetAsLost(data) {
  const req = {
    pets: data.pets,
    userEmailAddress: data.userEmailAddress,
    location_long: data.location_long,
    location_lat: data.location_lat,
  };
  return http.post(apiEndpoint + 'reportPetAsLost/', req);
}

export function shareLocation(id, data) {
  const req = {
    location_long: data.location_long,
    location_lat: data.location_lat,
  };
  return http.post(apiEndpoint + 'shareLocation/' + id + '/', req);
}
export function lostPetcontactOwner(data) {
  const req = {
    message: 'aaaa', //data.message,
    location_long: data.location_long,
    location_lat: data.location_lat,
    panic_lost_id: data.panic_lost_id,
  };
  return http.post(apiEndpoint + 'lostPetcontactOwner/' + data.petID + '/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function joinSearch(id, email) {
  const req = {
    participantEmailAddress: email,
  };
  return http.post(apiEndpoint + 'joinSearch/' + id + '/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function infoReportedPetAsLost(lostId, petId) {
  return http.get(apiEndpoint + 'infoReportedPetAsLost/' + lostId + '/' + petId + '/');
}

export function listPetAsLost() {
  return http.get(apiEndpoint + 'PetsReportedAsLost/');
}

export function getPosthogEvent() {
  return axios.get('https://app.posthog.com/api/projects/9750/events/?event=EventLostPet', {
    headers: {
      Authorization: 'Bearer phx_esMdZqrZ48fbxk9jmf3yhQRUAcTuKDVmNFCiq5MxXw4',
    },
  });
}

export function sendEmailinjuredPet(id) {
  return http.post(apiEndpoint + 'sendEmailinjuredPet/' + id + '/');
}

export function reportInjuredPet(data) {
  const req = {
    pets: data.pets,
    userEmailAddress: data.userEmailAddress,
    location_long: data.location_long,
    location_lat: data.location_lat,
    vetEmailAddress: data.vetEmailAddress,
  };
  return axios.post(apiEndpoint + 'reportInjuredPet/', req);
}

export function closerEmergencyVet(location_long, location_lat) {
  const req = {
    location_long: location_long,
    location_lat: location_lat,
  };
  return axios.post(apiEndpoint + 'closerEmergencyVet/', req);
}

export function addGalleryVedio(id, vedio_link) {
  const formData = new FormData();
  formData.append('panic_injured_id', id);
  formData.append('video_link', vedio_link);

  return axios
    .post(apiUrl + 'panicButton/injuredPetGallery/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      // console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
}
export function addGalleryPicture(id, photo_link) {
  const formData = new FormData();
  formData.append('panic_injured_id', id);
  formData.append('photo_link', photo_link);

  return axios
    .post(apiUrl + 'panicButton/injuredPetGallery/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then((response) => {
      //console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
}
