import {
  getContactByUser,
  getcontactDetails,
  contactRequest,
  deleteContact,
  contactAcceptOrDecline,
  getPendingContactRequestsOfUser,
  revokeSharepetsWithContact,
  sharepetsWithContact,
  addContact,
  addNewContact,
} from "../services/contactService";

import {
  addEmergencyContact,
  listEmergencyContact,
  listFavouriteServices,
  confirmEmergencyContact,
  showEmergencyContact,
  hideEmergencyContact,
} from "../services/commonService";
import { Promise } from "core-js";

export default {
  namespaced: true,

  state: {
    contacts: [],
    emergencyContacts: [],
    favouriteServices: [],
    contactDetail: {},
    pendingContactsRequest: [],
    error: "",
  },

  getters: {
    getContacts(state) {
      return state.contacts;
    },
    getEmergencyContacts(state) {
      return state.emergencyContacts;
    },
    getFavouriteServices(state) {
      return state.favouriteServices;
    },
    getContactDetails(state) {
      return state.contactDetail;
    },
    getPendingContactsRequest(state) {
      return state.pendingContactsRequest;
    },
    getError(state) {
      return state.error;
    },
  },

  mutations: {
    setContacts(state, data) {
      state.contacts = data;
    },
    setEmergencyContacts(state, data) {
      state.emergencyContacts = data;
    },
    setFavouriteServices(state, data) {
      state.favouriteServices = data;
    },

    setContactDetails(state, data) {
      state.contactDetail = data;
    },

    newContact(state, data) {
      data.profile_picture = null;
      data.name = null;
      data.contactEmailAddress = data.contactEmailAddress;
      data.userEmailAddress = data.userEmailAddress;
      data.name_label = data.name_label;

      state.contacts = [...state.contacts, data];
    },

    newPendingContactsRequest(state, data) {
      console.log(data);
      data.status = 1;
      data.notes = "";
      data.contactEmailAddress = data.contactEmailAddress;
      data.userEmailAddress = data.userEmailAddress;
      data.name_label = data.name_label;

      data.contactEmailAddress == data.userEmailAddress ? (state.pendingContactsRequest = [...state.pendingContactsRequest, data]) : "";
    },

    updateContact(state, contact) {
      const index = state.contacts.findIndex((t) => t.id === contact.id);
      if (index !== -1) {
        contact.checked = !contact.checked;
        state.contacts.splice(index, 1, contact);
      }
    },

    setPendingContactsRequest(state, data) {
      state.pendingContactsRequest = data;
    },
    deleteContact(state, data) {
      state.contacts = state.contacts.filter((p) => data.id !== p.id);
    },
    deletePendingContactsRequest(state, data) {
      state.pendingContactsRequest = state.pendingContactsRequest.filter((p) => data.id !== p.id);
    },
    error(state, data) {
      return (state.error = data);
    },
  },

  actions: {
    async getContacts({ commit }, data) {
      commit("showLoader", true, { root: true });
      await getContactByUser(data).then((result) => {
        console.log(result);
        const r = result.data;
        //console.log(r);
        const cont = [];
        r.map((s) => {
          s["checked"] = false;
          cont.push(s);
        });

        let newcontact = [...new Set(cont)];

        commit("setContacts", newcontact);
        commit("showLoader", false, { root: true });
      });
    },
    async getEmergencyContacts({ commit }, data) {
      try {
        const { data: result } = await listEmergencyContact(data);
        commit("setEmergencyContacts", result);
      } catch (error) {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      }
    },
    async getFavouriteServices({ commit }, data) {
      try {
        const { data: result } = await listFavouriteServices(data);
        console.log(result);
        commit("setFavouriteServices", result);
      } catch (error) {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      }
    },
    async getContactDetails({ commit }, data) {
      try {
        const { data: result } = await getcontactDetails(data);

        commit("setContactDetails", result);
      } catch (error) {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      }
    },

    async getPendingContactsRequest({ commit }, data) {
      try {
        const { data: result } = await getPendingContactRequestsOfUser(data);

        commit(
          "setPendingContactsRequest",
          result.contacts_requests.filter((item) => item.status == 1)
        );
      } catch (error) {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      }
    },
    async contactRequest({ commit }, data) {
      try {
        await contactRequest(data).then((response) => {
          console.log(response);
          commit("newPendingContactsRequest", data);
          commit("errorForm", "", { root: true });
          commit("addNonContactRequestModals", false, { root: true });
        });
      } catch (error) {
        if (error && error.response) {
          commit("errorForm", "API internal error.", { root: true });
        }
      }
    },
    async addnewcontact({ commit }, data, id) {
      await addNewContact(data).then((response) => {
        console.log(response);
        commit("newContact", data);
        commit("newPendingContactsRequest", data);
        commit("result", response.data, { root: true });
        commit("errorForm", "", { root: true });
        id == 0 ? commit("addNonContactRequestModals", true, { root: true }) : commit("showAddPhoneEmailModals", false, { root: true });
      });
    },

    async addContact({ commit }, data) {
      await addContact(data).then(() => {
        commit("errorForm", "", { root: true });
        commit("contactDetailsModals", false, { root: true });
      });
    },

    async addEmergencyContact({ commit }, data) {
      await addEmergencyContact(data).then(() => {
        commit("errorForm", "", { root: true });
        commit("showAddPhoneEmailModals", false, { root: true });
      });
    },

    async confirmEmergencyContact({ commit }, data) {
      await confirmEmergencyContact(data).then(() => {
        commit("errorForm", "", { root: true });
      });
    },

    async showEmergencyContact({ commit }, data) {
      await showEmergencyContact(data).then(() => {
        commit("errorForm", "", { root: true });
        commit("emergencyContactModals", false, { root: true });
      });
    },

    async hideEmergencyContact({ commit }, data) {
      await hideEmergencyContact(data).then(() => {
        commit("errorForm", "", { root: true });
        commit("emergencyContactModals", false, { root: true });
      });
    },

    async addEmergencyContactList({ commit }, data) {
      await addEmergencyContact(data).then(() => {
        commit("errorForm", "", { root: true });
        commit("emergencyContactModals", false, { root: true });
      });
    },

    async deleteContact({ commit, dispatch }, data) {
      await deleteContact(data.id)
        .then(() => {
          commit("deleteContact", data);
          //commit("showDeleteModals", false, { root: true });
          //router.push("/petoverview/news");
        })
        .catch((error) => {
          console.log(error.response.data.message || error.message);
          commit("error", error);
        })
        .finally(() => {
          commit("showDeleteModals", false, { root: true });
        });
    },

    async revokeSharepetsWithContact({ commit, dispatch }, data) {
      await revokeSharepetsWithContact(data).then((response) => {
        console.log("---------", response);
        console.log("---------", data);
        //commit("updateContact", data);
        console.log("----/-----", data);
      });
    },

    async sharepetsWithContact({ commit, dispatch }, data) {
      await sharepetsWithContact(data).then(() => {
        console.log("---------");
        console.log("---------", data);
        commit("updateContact", data);

        console.log("----/-----", data);
      });
    },

    async contactAcceptOrDecline({ commit }, data) {
      await contactAcceptOrDecline(data).then(() => {
        if (data.status == 2) {
          //commit("newContact", data);
          commit("deletePendingContactsRequest", data);
        } else if (data.status == 3) {
          commit("deletePendingContactsRequest", data);
        }
        commit("errorForm", "", { root: true });
      });
    },
  },
};
