import {
  petSharingRulesList,
  shareSettings,
  addPetSharingURL,
  shareByEmail,
  updatePetSharing,
  shareMedicalHistory,
  userSharingPreferences,
  getuserSharingPreferences,
} from '../services/sharingService';
import router from '../router';

import moment from 'moment';

const state = {
  sharingRules: [],
  sharingRulesPreferences: [],
  codeQR: '',
  link: '',
  sharingEmailPhoneModalsState: false,
  error: '',
};

const getters = {
  getSharingRules(state) {
    return state.sharingRules;
  },
  getSharingRulesPreferences(state) {
    return state.sharingRulesPreferences;
  },
  getCodeQR(state) {
    return state.codeQR;
  },
  getLink(state) {
    return state.link;
  },
  getSharingEmailPhoneModals(state) {
    return state.sharingEmailPhoneModalsState;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setSharingRules(state, sharingRules) {
    state.sharingRules = sharingRules;
  },
  setSharingRulesPreferences(state, sharingRulesPreferences) {
    state.sharingRulesPreferences = sharingRulesPreferences;
  },
  setCodeQR(state, qr) {
    state.codeQR = qr;
  },
  setLink(state, link) {
    state.link = link;
  },
  newSharingRule(state, sharingRule) {
    state.sharingRules = [...state.sharingRules, sharingRule];
  },
  updSharingRule(state, updatedSharingRule) {
    const index = state.sharingRules.findIndex((t) => t.id === updatedSharingRule.id);
    if (index !== -1) {
      state.sharingRules.splice(index, 1, updatedSharingRule);
    }
  },
  sharingEmailPhoneModals(state, v) {
    state.sharingEmailPhoneModalsState = v;
  },
  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getSharingRules({ commit }, id) {
    const response = await petSharingRulesList(id);
    const today = new Date();
    const r = response.data;
    /*  const val = r.filter(
      (item) => moment(item.expiration_date).format('DD-MM-YYYY') >= moment(today).format('DD-MM-YYYY')
    ); */
    console.log(response.data);

    commit('setSharingRules', r);
  },
  async getuserSharingPreferences({ commit }, id) {
    const response = await getuserSharingPreferences(id);

    console.log(' getuserSharingPreferences : ', response.data);
    /* FIXME: catch if 404 error message  */
    const a = [];
    if (response.data.length > 0) {
      response.data.map((item) => {
        a.push(item.id);
      });
    }

    localStorage.setItem('sharingRulesPreferences', JSON.stringify(a));

    commit('setSharingRulesPreferences', a);
  },
  async sharingEmailPhoneModals({ commit }, data) {
    commit('sharingEmailPhoneModals', data);
  },

  async addSharingRule({ commit }, { sharingRule, choice, link }) {
    console.log(sharingRule, choice);
    if (link != null) {
      console.log(link);
      choice == 1 ? commit('setLink', link) : choice == 2 ? commit('setCodeQR', link) : null;
    } else {
      console.log('************', sharingRule);
      const { data: response } = await shareSettings(sharingRule, choice);
      console.log('*************', response);
      if (choice == 1) commit('setLink', response.link_to_share);
      if (choice == 2) commit('setCodeQR', response.qrcode_data);

      const res = await petSharingRulesList(sharingRule.petID);
      console.log(res.data);

      commit('setSharingRules', res.data);
    }
  },
  async userSharingPreferences({ commit }, data) {
    const { data: response } = await userSharingPreferences(data);
    /* FIXME: commit  */
    const a = [];
    if (response.shareable_objects.length > 0) {
      response.shareable_objects.map((item) => {
        a.push(item);
      });
    }
    commit('setSharingRulesPreferences', a);
    localStorage.setItem('sharingRulesPreferences', JSON.stringify(a));
    console.log('*****************', response);
  },

  async addSharingRuleURL({ commit }, sharingRule) {
    const response = await addPetSharingURL(sharingRule);

    const data = {
      date_created: sharingRule.expiration_date,
      expiration_date: sharingRule.expiration_date,
      granted_by: sharingRule.granted_by,
      id: 0,
      is_enable: false,
      is_private: false,
      notes: sharingRule.notes,
      petID: sharingRule.petID,
      qrcode: null,
      shareable_objects: sharingRule.shareable_objects,
      url: response.data.link_to_share,
    };
    console.log(data);
    /*  const res = await petSharingRulesList(sharingRule.petID);
      console.log(res.data); */

    commit('newSharingRule', data);
    return response.data.link_to_share;
  },

  async shareByEmail({ commit }, sharingRule) {
    console.log(sharingRule.shareable_objects, sharingRule.newJoinerEmailAddress);
    if (sharingRule.shareable_objects.includes(Number(8))) {
      alert('ok');
      await shareMedicalHistory(sharingRule.newJoinerEmailAddress, sharingRule.granted_by, sharingRule.petID);
    }
    sharingRule.shareable_objects = sharingRule.shareable_objects.filter((item) => item != '8');
    console.log(sharingRule.shareable_objects);

    if (sharingRule.shareable_objects.length != 0) {
      const response = await shareByEmail(sharingRule);
      console.log(response);
      const res = await petSharingRulesList(sharingRule.petID);
      console.log(res.data);

      commit('setSharingRules', res.data);
    }

    router.push('/sharingsetting');
    commit('sharingEmailPhoneModals', false);
    commit('errorForm', '', { root: true });
  },

  async updateSharingRule({ commit }, sharingRule) {
    const response = await updatePetSharing(sharingRule);
    console.log(response);
    commit('updSharingRule', response.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
