import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

export function breedsBySpecies(id) {
  return http.get(apiUrl + 'petRecords/species/' + id + '/breeds/');
}
export function petBreedPdfLink(id) {
  return http.get(apiUrl + 'petRecords/petBreedPdfLink/' + id);
}

export function petRecordById(id) {
  return http.get(apiUrl + 'petRecords/petRecord/' + id);
}

export function deletePetRecord(id) {
  return http.delete(apiUrl + 'petRecords/petRecord/' + id);
}

export function petsByUser(id) {
  console.log(http);
  return http.get(apiUrl + 'petRecords/petsByUser/' + id);
}

export function petDocumentDirectus(id) {
  return axios.get(apiUrl + 'petRecords/directus/petRecord/' + id + '/petDocument/', {
    headers: {
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function petGualleryDirectus(id) {
  return axios.get(apiUrl + 'petRecords/directus/petRecord/' + id + '/petGallery/', {
    headers: {
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getPetProfile(id) {
  return http.get(apiUrl + 'petRecords/petProfile/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getPetRecordHistory(id) {
  return axios.get(apiUrl + 'petRecords/PetRecordHistory/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getPetProfileApi(apiString) {
  return axios.get(apiUrl + 'sharing/publicByUrlQR/' + apiString);
}

export function getGallery(id) {
  return axios.get(apiUrl + 'petRecords/petRecord/' + id + '/gallery/', {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getDocument(id) {
  return axios.get(apiUrl + 'petRecords/petRecord/' + id + '/petDocument/', {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function nationalKennelClubsBySpecies(id) {
  return http.get(apiUrl + 'petRecords/species/' + id + '/nationalKennelClubs/');
}

export function genusBird() {
  return http.get(apiUrl + 'petRecords/genus/');
}

//----------------------------------save pet record -------------------------------------------------------------
export function savePetRecord(data, userEmail, vetEmailAddress, unverifiedHealthStatus, petProfileImg, petGallery) {
  const formData = new FormData();
  //  1 dog -2 cat -3 ferret -4 bird -5 other
  console.log(
    '------------',
    data.showTitle == [] ? true : false,
    data.showTitle.length == 0 ? true : false,
    data.showTitle,
    typeof data.showTitle
  );
  console.log(data, userEmail, vetEmailAddress, unverifiedHealthStatus, petProfileImg, petGallery);
  petProfileImg ? formData.append('petProfileImg', petProfileImg) : null;

  data.showTitle.forEach((item) => formData.append(`showTitle`, item));
  data.workingTitle.forEach((item) => formData.append(`workingTitle`, item));
  formData.append('callName', data.callName);
  formData.append('species', data.species);
  formData.append('CountryofOrigin', data.CountryofOrigin);
  formData.append('sex', data.sex ? 1 : 0);
  data.dateOfBirth ? formData.append('dateOfBirth', data.dateOfBirth) : null;
  data.approximateAgeInMonths != '' ? formData.append('approximateAgeInMonths', data.approximateAgeInMonths) : '';
  formData.append('primaryOptionalID', data.primaryOptionalID);
  formData.append('primaryOptionalIDType', data.primaryOptionalIDType);
  formData.append('secondaryOptionalID', data.secondaryOptionalID);
  formData.append('secondaryOptionalIDType', data.secondaryOptionalIDType);
  formData.append('height', data.height);
  formData.append('weight', data.weight);
  petGallery.forEach((item) => formData.append('petGallery', item));
  formData.append('userEmailAddress', userEmail);
  formData.append('vetEmailAddress', vetEmailAddress);
  data.microchipNo != '' ? formData.append('microchipNo', data.microchipNo) : null; //1 2 3 4 5
  data.petIdentificationImage ? formData.append('petIdentificationImage', data.petIdentificationImage) : null;
  data.petIdentificationImage ? formData.append('petIdentificationImageType', data.petIdentificationImageType) : null;
  unverifiedHealthStatus.forEach((item) => formData.append('unverifiedHealthStatus', item));
  //14

  if (data.species == 1 || data.species == 2) {
    // formData.append("passportNo", data.passportNo); // 2 1
    formData.append('breed', data.breed); // 1 2
    formData.append('purebredStatus', data.purebredStatus); //1 2
    formData.append('pedigree', data.pedigree); //1 2
    formData.append('pedigreeNo', data.pedigreeNo); //1 2
    formData.append('nationalRegistry', data.nationalRegistry); //1 2
    formData.append('officialRegisteredName', data.officialRegisteredName); //1 2
    formData.append('hasPedigreePapers', data.pedigree); //1 2
  }

  if (data.species != 4) {
    formData.append('breedingStatus', data.breedingStatus); //1 2 3 5
  }

  if (data.species == 4) {
    formData.append('CITESCert', data.CITESCert ? 1 : 0); // 4
    // formData.append("idBandNo", data.idBandNo); // 4
    formData.append('genus', data.genus); // 4
  }

  if (data.species == 5) {
    formData.append('customSpecies', data.customSpecies); // 5
  }

  return axios.post(apiUrl + 'petRecords/petRecord/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
//----------------------------------End save pet record -------------------------------------------------------------

export function updatePetRecord(data, userEmail, vetEmailAddress, unverifiedHealthStatus, petProfileImg, petGallery) {
  const formData = new FormData();
  //  1 dog 2 cat 3 ferret 4 bird 5 other
  console.log('99999999 ', data.workingTitle.length, JSON.stringify(data.workingTitle));
  formData.append('VerificationStatus', 0);
  formData.append('petID', data.petID);
  console.log(petProfileImg);
  petProfileImg ? formData.append('petProfileImg', petProfileImg) : null;
  //data.showTitle.length == 0 ? formData.append("showTitle", []) : formData.append("showTitle", JSON.stringify(data.showTitle));
  data.showTitle ? data.showTitle.forEach((item) => formData.append('showTitle', item)) : null;
  data.workingTitle ? data.workingTitle.forEach((item) => formData.append('workingTitle', item)) : null;
  //data.workingTitle.length == 0 ? formData.append("workingTitle", []) : formData.append("workingTitle", JSON.stringify(data.workingTitle));
  formData.append('callName', data.callName);
  formData.append('species', data.species);
  formData.append('CountryofOrigin', data.CountryofOrigin);
  formData.append('sex', data.sex ? 1 : 0);
  data.dateOfBirth ? formData.append('dateOfBirth', data.dateOfBirth) : null;
  formData.append('optionalID', data.optionalID);
  formData.append('optionalIDType', data.optionalIDType);
  formData.append('height', data.height);
  formData.append('weight', data.weight);
  petGallery.forEach((item) => formData.append('petGallery', item));
  formData.append('userEmailAddress', userEmail);
  formData.append('vetEmailAddress', vetEmailAddress);
  console.log('--------', data.petIdentificationImage);
  data.petIdentificationImage ? formData.append('petIdentificationImage', data.petIdentificationImage) : null;
  data.petIdentificationImage ? formData.append('petIdentificationImageType', data.petIdentificationImageType) : null;
  unverifiedHealthStatus.forEach((item) => formData.append('unverifiedHealthStatus', item));
  //14

  if (data.species == 1 || data.species == 2) {
    formData.append('passportNo', data.passportNo); // 2 1
    formData.append('breed', data.breed); // 1 2
    formData.append('purebredStatus', data.purebredStatus); //1 2
    formData.append('pedigree', data.pedigree); //1 2
    formData.append('pedigreeNo', data.pedigreeNo); //1 2
    formData.append('nationalRegistry', data.nationalRegistry); //1 2
    formData.append('officialRegisteredName', data.officialRegisteredName); //1 2
    formData.append('hasPedigreePapers', data.pedigree); //1 2
  }

  if (data.species != 4) {
    formData.append('breedingStatus', data.breedingStatus); //1 2 3 5
  }

  if (data.species != 4 && data.species != 5) {
    formData.append('microchipNo', data.microchipNo); //1 2 3
  }

  if (data.species == 4) {
    formData.append('CITESCert', data.CITESCert ? 1 : 0); // 4
    formData.append('idBandNo', data.idBandNo); // 4
    formData.append('genus', data.genus); // 4
  }

  if (data.species == 5) {
    formData.append('customSpecies', data.customSpecies); // 5
  }

  return http.put(apiUrl + 'petRecords/petRecord/' + data.petID, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function savePetGallery(userEmailAddress, petImage, petID, primary_picture) {
  console.log('savePetGallery', userEmailAddress, petImage, petID, primary_picture);
  const formData = new FormData();
  formData.append('userEmailAddress', userEmailAddress);
  formData.append('petImage', petImage);
  formData.append('petID', petID);
  formData.append('primary_picture', primary_picture);

  return http.post(apiUrl + 'petRecords/petGallery/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function updatePetGallery(id, userEmailAddress, petImage, petID, primary_picture) {
  console.log('petGallery', userEmailAddress, petImage, petID, primary_picture);
  const formData = new FormData();
  formData.append('userEmailAddress', userEmailAddress);
  formData.append('petImage', petImage);
  formData.append('petID', petID);
  formData.append('primary_picture', primary_picture);

  return http.put(apiUrl + 'petRecords/petGallery/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function deletePetGallery(id) {
  return http.delete(apiUrl + 'petRecords/petGallery/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function deletePetDoc(id) {
  return http.delete(apiUrl + 'petRecords/petDocument/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function savePetDocument(type, userEmailAddress, petID, document) {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('userEmailAddress', userEmailAddress);
  formData.append('petID', petID);
  formData.append('document', document);

  return http.post(apiUrl + 'petRecords/petDocument/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
