import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

export function sendOtp(data) {
  return axios.post(apiUrl + 'sms/registration/sendOtp', {
    email_address: data.email,
    phone_number: data.phonenumber,
    lang: data.lang,
    role: data.role,
    servicePracticeType: data.servicePracticeType,
  });
}

export function loginSendOtp(phone_number, lang) {
  return axios.post(apiUrl + 'sms/login/sendOtp', {
    phone_number: phone_number,
    lang: lang,
  });
}

export function verifyOtp(email_address, phone_number, otp) {
  return axios.get(apiUrl + 'sms/registration/verifyOtp', {
    params: {
      email_address: email_address,
      phone_number: phone_number,
      otp: otp,
    },
  });
}

export function loginVerifyOtp(phone_number, otp) {
  return axios.get(`${apiUrl}sms/login/verifyOtp?phone_number=${phone_number}&otp=${otp}`);
}
