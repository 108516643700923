import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import transferownership from './transferownership';
import petRecord from './petRecord';
import appointment from './appointment';
import sharing from './sharing';
import contact from './contact';
import servicedirectory from './servicedirectory';
import breederdirectory from './breederdirectory';
import medicalhistory from './medicalhistory';
import registrationlogin from './registrationlogin';
import sharefile from './sharefile';
import panicbutton from './panicbutton';
import serviceprovider from './serviceprovider';
import healthlog from './healthlog';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountState: null,
    pictureState: null,
    regType: '',
    petoverviewTab: 1,
    petStatusName: '',
    errorFormEmail: '',
    errorFormPhone: '',
    errorForm: '',
    editable: false,
    result: {},
    infOtp: '',
    bulkShareModalsState: false,
    routinesState: [],
    addEmergencyContactModalsState: false,
    symptomTrackerModalsState: false,
    pastIllnessModalsState: false,
    suggestModalsState: false,
    transferModalsState: false,
    primaryRightModalsState: false,
    showDeleteModalsState: false,
    showAddPhoneEmailModalsState: false,
    emergencyContactModalsState: false,
    contactDetailsModalsState: false,
    showMsgModalsState: false,
    showLoaderState: false,
    recentActivityState: false,
    fullyRightModalsState: false,
    showNutritionModalsState: false,
    showDiagnosisModalsState: false,
    bloodDonorModalsState: false,
    showEditNutritionModalsState: false,
    inviteCoownerModalsState: false,
    confirmIdModalsState: false,
    touchidModalsState: false,
    faceidModalsState: false,
    confirmOtpModalsState: false,
    manageBreederModalsState: false,
    pereviewFileShareModalsState: false,
    confirmFileShareModalsState: false,
    transferredModalsState: false,
    notifOwnershipStatusModalsState: false,
    sharingBarcodeModalsState: false,
    addNoteModalsState: false,
    addNonContactRequestState: false,
    petStatusModalsState: false,
    petConfirmeIdentityModalsState: false,
    careBlogDiaryModalsState: false,
    careBlogAppointmentModalsState: false,
    sttStartModalsState: false,
    transcribeRecordingModalsState: false,
    publishTranscriptModalsState: false,
    manageContactDetailsModalsState: false,
    manageServiceDetailsModalsState: false,
    manageProfileImagesModalsState: false,
    setOpeningHourModalsState: false,
    setOpeningHourModalsState: false,
    openNavHealthVState: false,
    symptomManagerModalsState: false,
    petStatusDropdownState: {},
    mapLocationLatState: 0,
    mapLocationLonState: 0,
    petStatusIdDropdown: '',
    petStore: {},
  },

  mutations: {
    accountState(state, v) {
      state.accountState = v;
    },
    pictureState(state, v) {
      state.pictureState = v;
    },
    setErrors(state, v) {
      state.errors = v;
    },
    regType(state, v) {
      state.regType = v;
    },
    PetoverviewTab(state, v) {
      state.petoverviewTab = v;
    },
    petStatusName(state, v) {
      state.petStatusName = v;
    },
    errorFormEmail(state, v) {
      state.errorFormEmail = v;
    },
    errorFormPhone(state, v) {
      state.errorFormPhone = v;
    },
    errorForm(state, v) {
      state.errorForm = v;
    },
    editable(state, v) {
      state.editable = v;
    },
    result(state, v) {
      state.result = v;
    },
    infOtp(state, v) {
      state.infOtp = v;
    },
    transferModals(state, v) {
      state.transferModalsState = v;
    },
    primaryRightModals(state, v) {
      state.primaryRightModalsState = v;
    },
    showDeleteModals(state, v) {
      state.showDeleteModalsState = v;
    },
    showAddPhoneEmailModals(state, v) {
      state.showAddPhoneEmailModalsState = v;
    },
    emergencyContactModals(state, v) {
      state.emergencyContactModalsState = v;
    },
    contactDetailsModals(state, v) {
      state.contactDetailsModalsState = v;
    },
    showMsgModals(state, v) {
      state.showMsgModalsState = v;
    },
    showLoader(state, v) {
      state.showLoaderState = v;
    },
    recentActivity(state, v) {
      state.recentActivityState = v;
    },
    fullyRightModals(state, v) {
      state.fullyRightModalsState = v;
    },
    showNutritionModals(state, v) {
      state.showNutritionModalsState = v;
    },
    showDiagnosisModals(state, v) {
      state.showDiagnosisModalsState = v;
    },
    bloodDonorModals(state, v) {
      state.bloodDonorModalsState = v;
    },
    bulkShareModals(state, v) {
      state.bulkShareModalsState = v;
    },
    routinesComp(state, v) {
      state.routinesState = v;
    },
    addEmergencyContactModals(state, v) {
      state.addEmergencyContactModalsState = v;
    },
    symptomTrackerModals(state, v) {
      state.symptomTrackerModalsState = v;
    },
    pastIllnessModals(state, v) {
      state.pastIllnessModalsState = v;
    },
    suggestModals(state, v) {
      state.suggestModalsState = v;
    },
    showEditNutritionModals(state, v) {
      state.showEditNutritionModalsState = v;
    },
    inviteCoownerModals(state, v) {
      state.inviteCoownerModalsState = v;
    },
    confirmIdModals(state, v) {
      state.confirmIdModalsState = v;
    },
    touchidModals(state, v) {
      state.touchidModalsState = v;
    },
    faceidModals(state, v) {
      state.faceidModalsState = v;
    },
    confirmOtpModals(state, v) {
      state.confirmOtpModalsState = v;
    },
    manageBreederModals(state, v) {
      state.manageBreederModalsState = v;
    },
    pereviewFileShareModals(state, v) {
      state.pereviewFileShareModalsState = v;
    },
    confirmFileShareModals(state, v) {
      state.confirmFileShareModalsState = v;
    },
    transferredModals(state, v) {
      state.transferredModalsState = v;
    },
    notifOwnershipStatusModals(state, v) {
      state.notifOwnershipStatusModalsState = v;
    },
    sharingBarcodeModals(state, v) {
      state.sharingBarcodeModalsState = v;
    },
    petStore(state, v) {
      state.petStore = v;
    },
    addNoteModals(state, v) {
      state.addNoteModalsState = v;
    },
    addNonContactRequestModals(state, v) {
      state.addNonContactRequestState = v;
    },
    petStatusModals(state, v) {
      state.petStatusModalsState = v;
    },
    petConfirmeIdentityModals(state, v) {
      state.petConfirmeIdentityModalsState = v;
    },
    transferModals(state, v) {
      state.transferModalsState = v;
    },
    careBlogDiaryModals(state, v) {
      state.careBlogDiaryModalsState = v;
    },
    careBlogAppointmentModals(state, v) {
      state.careBlogAppointmentModalsState = v;
    },
    careBlogAppointmentModals(state, v) {
      state.careBlogAppointmentModalsState = v;
    },
    sttStartModals(state, v) {
      state.sttStartModalsState = v;
    },
    transcribeRecordingModals(state, v) {
      state.transcribeRecordingModalsState = v;
    },
    publishTranscriptModals(state, v) {
      state.publishTranscriptModalsState = v;
    },
    manageContactDetailsModals(state, v) {
      state.manageContactDetailsModalsState = v;
    },
    manageServiceDetailsModals(state, v) {
      state.manageServiceDetailsModalsState = v;
    },
    manageProfileImagesModals(state, v) {
      state.manageProfileImagesModalsState = v;
    },
    setOpeningHourModals(state, v) {
      state.setOpeningHourModalsState = v;
    },
    openNavHealthv(state, v) {
      state.openNavHealthVState = v;
    },
    symptomManagerModals(state, v) {
      state.symptomManagerModalsState = v;
    },
    petStatusDropdown(state, { i, v, t, a }) {
      state.petStatusDropdownState = {
        status: i,
        statusId: a,
        trans: t,
        component: v,
      };
    },
    mapLocationLat(state, i) {
      console.log(i);
      state.mapLocationLatState = i;
    },
    mapLocationLon(state, i) {
      console.log(i);
      state.mapLocationLonState = i;
    },
    mapLocationLat(state, i) {
      console.log(i);
      state.mapLocationLatState = i;
    },
    mapLocationLon(state, i) {
      console.log(i);
      state.mapLocationLonState = i;
    },
  },

  actions: {},
  modules: {
    namespaced: true,
    auth,
    transferownership,
    petRecord,
    servicedirectory,
    breederdirectory,
    appointment,
    sharing,
    contact,
    medicalhistory,
    registrationlogin,
    sharefile,
    panicbutton,
    serviceprovider,
    healthlog,
  },
});
