import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

const apiEndpoint = apiUrl + 'sharing/';

export function privateGuestByUrl(code) {
  return axios.get(`${apiEndpoint}privateGuestByUrl/${code}`);
}

export function publicPetProfileSharedToSP(code) {
  return axios.get(`${apiEndpoint}publicPetProfileSharedToSP/${code}`);
}

export function petSharingRulesList(id) {
  return axios.get(apiEndpoint + 'petSharingRulesList?petID=' + id);
}
export function getuserSharingPreferences(id) {
  return http.get(apiEndpoint + 'userSharingPreferences/' + id);
}

export function addPetSharingQR(data) {
  const req = {
    granted_by: data.granted_by,
    petID: data.petID,
    shareable_objects: data.shareable_objects,
    notes: data.notes,
  };
  data.expiration_date !== null ? (req.expiration_date = data.expiration_date) : null;
  return http.post(apiEndpoint + 'generateQR/', req);
}
export function userSharingPreferences(data) {
  const req = {
    userEmailAddress: data.userEmailAddress,
    shareable_objects: data.shareable_objects,
  };

  return http.post(apiEndpoint + 'userSharingPreferences/', req);
}

export function addPetSharingURL(data) {
  const req = {
    granted_by: data.granted_by,
    petID: data.petID,
    shareable_objects: data.shareable_objects,
    notes: data.notes,
    expiration_date: data.expiration_date,
  };
  return http.post(apiEndpoint + 'generateUrl/', req);
}
export function updatePetSharing(data) {
  const req = {
    id: data.id,
    date_created: data.date_created,
    granted_by: data.granted_by,
    petID: data.petID,
    notes: data.notes,
    expiration_date: data.expiration_date,
    is_enable: data.is_enable,
    qrcode: data.qrcode,
    url: data.url,
    is_private: data.is_private,
    shareable_objects: data.shareable_objects,
  };
  //return http.put(apiEndpoint + 'petSharingRule/' + data.id, req);
  return http.post(apiEndpoint + 'enablePetSharingRule/' + data.id);
}

export function shareCareBlog(data) {
  const req = {
    spEmailAddress: data.spEmailAddress,
    granted_by: data.granted_by,
    petID: data.petID,
  };
  console.log(req);
  return http.post(apiEndpoint + 'shareCareBlog/', req);
}

export function enableSharingCareBlog(id) {
  return axios.post(apiEndpoint + 'enableSharingCareBlog/' + id);
}

export function stopSharingCareBlog(id) {
  return axios.post(apiEndpoint + 'stopSharingCareBlog/' + id);
}

export function shareByEmail(data) {
  console.log(data);
  const req = {
    granted_by: data.granted_by,
    petID: data.petID,
    shareable_objects: data.shareable_objects,
    notes: data.notes,
  };
  console.log(data.newJoinerEmailAddress ? console.log('ok email') : console.log('no email'));
  console.log(data.newJoinerphoneNo ? console.log('ok phone') : console.log('no phone'));
  data.newJoinerEmailAddress ? (req.newJoinerEmailAddress = data.newJoinerEmailAddress) : null;
  data.newJoinerphoneNo ? (req.newJoinerphoneNo = data.newJoinerphoneNo) : null;
  data.expiration_date !== null ? (req.expiration_date = data.expiration_date) : null;

  console.log('req:', req);

  return http.post(apiEndpoint + 'privateSharing/', req);
}

export function shareSettings(data, choice) {
  return http.post(apiEndpoint + 'shareSettings/' + choice, data);
}

export function incognitoModeOn(id) {
  return http.post(apiEndpoint + 'incognitoModeOn/' + id, {});
}

export function shareMedicalHistory(email, granted_by, petID) {
  return axios.post(apiEndpoint + 'shareMedicalHistory/', {
    vetEmailAddress: email,
    granted_by: granted_by,
    petID: petID,
  });
}

export function incognitoModeOff(id) {
  return http.post(apiEndpoint + 'incognitoModeOff/' + id, {});
}

export function checkUrlQRExists(data) {
  if (data.expiration_date) {
    return http.get(`${apiEndpoint}checkUrlQRExists`, {
      params: {
        petID: data.petID,
        granted_by: data.granted_by,
        shareable_objects: data.shareable_objects,
        expiration_date: data.expiration_date,
      },
    });
  } else
    return http.get(`${apiEndpoint}checkUrlQRExists/`, {
      params: {
        petID: data.petID,
        granted_by: data.granted_by,
        shareable_objects: JSON.stringify(data.shareable_objects),
      },
    });
}
