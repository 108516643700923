import { apiUrl } from "../config.json";
import axios from "axios";

const state = {
  coownersList: "",
  otp: "",
  confirmTransferOwnership: "",
  deleteCoowner: "",
};
const getters = {
  getCoowners(state) {
    if (state.coownersList) {
      return state.coownersList.data;
    } else {
      return false;
    }
  },
  getOtp(state) {
    if (state.otp) {
      return state.otp.data.message;
    } else {
      return false;
    }
  },
  getConfirmTransferOwnership(state) {
    if (state.otp) {
      return state.confirmTransferOwnership;
    } else {
      return false;
    }
  },
};
const mutations = {
  mutCoowners(state, data) {
    state.coownersList = data;
  },
  mutConfirmTransferOwnership(state, data) {
    state.confirmTransferOwnership = data;
  },
  mutIdentityOtp(state, data) {
    state.otp = data;
  },
  mutDeleteCoowner(state, data) {
    state.deleteCoowner = data;
  },
};
const actions = {
  async getCoowners({ commit }, data) {
    console.log(data);
    const response = await axios({
      method: "GET",
      url: apiUrl + "petRecords/coownersOfPet/" + data,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        commit("mutCoowners", res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async confirmTransferOwnership({ commit, dispatch }, data) {
    console.log(data);
    const response = await axios({
      method: "POST",
      url: apiUrl + "transferOwnership/confirmTransferOwnership/",
      data: data,
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => {
        commit("mutConfirmTransferOwnership", res);
        // commit("notifOwnershipStatusModals", true, { root: true });
        commit("confirmOtpModals", false, { root: true });
        dispatch("getCoowners", res.data.petID);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async sendConfirmIdentityOtp({ commit }, data) {
    const response = await axios({
      method: "POST",
      url: apiUrl + "sms/sendConfirmIdentityOtp",
      data: data,
      // headers: { "Content-Type": "multipart/form-data", "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8", 'Authorization': 'Bearer ' + localStorage.getItem("token") },
    })
      .then((res) => {
        commit("mutIdentityOtp", res);
        commit("confirmOtpModals", true, { root: true });
        commit("inviteCoownerModals", false, { root: true });
        commit("fullyRightModals", false, { root: true });
        commit("primaryRightModals", false, { root: true });
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async deleteCoowner({ commit, dispatch }, data) {
    const response = await axios({
      method: "DELETE",
      url: apiUrl + "transferOwnership/removeCoOwner/" + data.petID + "/coOwners/" + data.userEmailAddress,
      data: data,
      // headers: { "Content-Type": "multipart/form-data", "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8", 'Authorization': 'Bearer ' + localStorage.getItem("token") },
    })
      .then((res) => {
        commit("mutDeleteCoowner", res);
        dispatch("getCoowners", data.petID);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
