import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

export function getuser(id, role) {
  if (role == 1) {
    return http.get(apiUrl + 'petOwners/petOwner/' + id);
  }
  if (role == 2) {
    console.log('her');
    return http.get(apiUrl + 'guests/guest/' + id);
  }
  if (role == 3) {
    return http.get(apiUrl + 'dogBreeders/dogBreeder/' + id);
  }
  if (role == 4) {
    return http.get(apiUrl + 'serviceProviders/serviceProvider/' + id);
  }
  if (role == 5) {
    return http.get(apiUrl + 'veterinarians/veterinarian/' + id);
  }
  if (role == 6) {
    return http.get(apiUrl + 'shelterOrganizations/shelterOrganization/' + id);
  }
}
export function saveUser(data) {
  const formData = new FormData();
  console.log('nnnnn', data);
  data.image ? formData.append('profileImage', data.image) : '';
  data.provider ? formData.append('provider', data.provider) : formData.append('provider', 'email');
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('streetAddress', data.streetAddress);
  formData.append('country', data.country);
  formData.append('postalCode', data.postalCode);
  formData.append('emailAddress', data.email_address);
  formData.append('phoneNo', data.phone_number);
  formData.append('zone_long', data.coordinates.lng);
  formData.append('zone_lat', data.coordinates.lat);
  formData.append('news_updates', data.checkboxNews);
  data.referral != '' ? formData.append('referral', data.referral) : '';

  if (data.role == 1) {
    return axios.post(`${apiUrl}petOwners/petOwner/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  if (data.role == 2) {
    formData.append('ruleID', data.ruleID);
    return axios.post(`${apiUrl}guests/guest/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
  /* if (role == 3) {
    return http.get(apiUrl + 'dogBreeders/dogBreeder/' + id);
  }
  if (role == 4) {
    return http.get(apiUrl + 'serviceProviders/serviceProvider/' + id);
  }
  if (role == 5) {
    return http.get(apiUrl + 'veterinarians/veterinarian/' + id);
  }
  if (role == 6) {
    return http.get(apiUrl + 'shelterOrganizations/shelterOrganization/' + id);
  } */
}

export function updateUser(id, role, data, profileImage) {
  const formData = new FormData();
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('streetAddress', data.streetAddress);
  formData.append('postalCode', data.postalCode);

  console.log(profileImage);
  profileImage ? formData.append('profileImage', profileImage) : null;
  formData.append('emailAddress', id);
  formData.append('phoneNo', data.phoneNo);
  formData.append('country', data.country);
  formData.append('state', data.state);
  formData.append('city', data.city);

  /* provider = serializers.CharField(max_length=255)
        news_updates = serializers.BooleanField()
        zone_long = serializers.DecimalField(max_digits=9, decimal_places=6)
        zone_lat =  serializers.DecimalField(max_digits=9, decimal_places=6) */

  if (role == 1) {
    return http.put(apiUrl + 'petOwners/petOwner/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  if (role == 2) {
    return http.put(apiUrl + 'guests/guest/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  if (role == 3) {
    return http.put(apiUrl + 'dogBreeders/dogBreeder/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  if (role == 4) {
    return http.put(apiUrl + 'serviceProviders/serviceProvider/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  if (role == 5) {
    return http.put(apiUrl + 'veterinarians/veterinarian/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
  if (role == 6) {
    return http.put(apiUrl + 'shelterOrganizations/shelterOrganization/' + id, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
