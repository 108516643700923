import { getContactByUser, contactRequest } from '../services/contactService';
import { sendOtp } from '../services/smsService';
import { sendRegistrationEmail } from '../services/emailService';
import { existEmailPhoneNumber } from '../services/authenticationService';
import router from '../router';

export default {
  namespaced: true,

  state: {
    contacts: [],
    error: '',
  },

  getters: {
    getContacts(state) {
      return state.contacts;
    },
    getError(state) {
      return state.error;
    },
  },

  mutations: {
    setContacts(state, data) {
      state.contacts = data;
    },

    error(state, data) {
      return (state.error = data);
    },
  },

  actions: {
    async verify_email_phoneNumber({ commit }, data) {
      commit('errorForm', '', { root: true });
      commit('infOtp', '', { root: true });
      console.log(data, '//////////////////');
      try {
        await existEmailPhoneNumber(data.email, data.phonenumber).then((response) => {});
      } catch (error) {
        if (error.response && error.response.status == 404) {
          try {
            await sendOtp(data).then(async (response) => {
              commit('errorForm', '', { root: true });
              console.log(response);
              //commit("infOtp", response.data.message, { root: true });
              commit('showLoader', false, { root: true });
              if (response.data.message != '') {
                try {
                  await sendRegistrationEmail(data).then(() => {
                    commit('errorForm', '', { root: true });
                    commit('showLoader', false, { root: true });
                    console.log(localStorage.getItem('referral'));
                    //setTimeout(() => {
                    if (localStorage.getItem('role') != 2) {
                      router.push({
                        name: 'MagicalLinkVerification',
                        params: {
                          email_address: data.email,
                          phonenumber: data.phonenumber,
                          role: data.role,
                          servicePracticeType: data.servicePracticeType,
                        },
                      });
                    } else {
                      router.push({
                        name: 'CompleteYourProfile',
                        params: {
                          email_address: data.email,
                          phone_number: data.phonenumber,
                          provider: 'email',
                        },
                      });
                    }
                    //}, 1000);
                  });
                } catch (error) {
                  commit('showLoader', false, { root: true });
                  if (error && error.response && error.response.status == 403) {
                    commit('errorForm', 'Email sending service is not available, please retry later.', { root: true });
                    commit('errorFormEmail', 'Email sending service is not available, please retry later.', {
                      root: true,
                    });
                  } else {
                    commit('errorFormEmail', 'API internal error, please retry later.', { root: true });
                    commit('errorForm', 'API internal error, please retry later.', { root: true });
                  }
                }
              }
            });
          } catch (error) {
            commit('showLoader', false, { root: true });

            if (error && error.response.status == 403) {
              //commit("errorFormPhone", "sms sending service is not available, please retry later.", { root: true });
              commit('errorForm', 'API internal error.', { root: true });
            } else {
              commit('errorFormEmail', 'API internal error, please retry later.', { root: true });
              commit('errorForm', 'API internal error, please retry later.', {
                root: true,
              });
            }
          }
        } else if (error.response && error.response.status == 302) {
          if (error.response.data['phone_number'] == true && error.response.data['email_address'] == true) {
            commit('errorForm', 'A user already exists with this email address and phone number', { root: true });
            commit('errorFormPhone', 'A user already exists with this email address and phone number', { root: true });
          } else if (error.response.data['email_address'] == true) {
            commit('errorForm', 'A user already exists with this email address', { root: true });
            commit('errorFormEmail', 'A user already exists with this email address', { root: true });
          } else if (error.response.data['phone_number'] == true) {
            commit('errorForm', 'A user already exists with this phone number', { root: true });
            commit('errorFormPhone', 'A user already exists with this phone number', { root: true });
          }
          commit('showLoader', false, { root: true });
        }
      }
    },

    async sendOtp({ commit }, data) {
      try {
        await sendOtp(data).then((response) => {
          commit('errorForm', '', { root: true });
          commit('infOtp', response.data.message, { root: true });
          commit('showLoader', false, { root: true });
        });
      } catch (error) {
        commit('showLoader', false, { root: true });

        if (error && error.response.status == 403) {
          commit('errorFormPhone', 'sms sending service is not available, please retry later.', { root: true });
          commit('errorForm', 'API internal error.', { root: true });
        } else {
          commit('errorFormEmail', 'API internal error, please retry later.', {
            root: true,
          });
          commit('errorForm', 'API internal error, please retry later.', {
            root: true,
          });
        }
      }
    },

    async send_MagicLink_Registration({ commit }, data) {
      //------------------ send magic link -----------
      try {
        await sendRegistrationEmail(data).then(() => {
          commit('errorForm', '', { root: true });
          commit('showLoader', false, { root: true });

          //setTimeout(() => {
          router.push({
            name: 'MagicalLinkVerification',
            params: {
              email_address: data.email,
              phonenumber: data.phonenumber,
              role: data.role,
              servicePracticeType: data.servicePracticeType,
            },
          });
          //}, 1000);
        });
      } catch (error) {
        commit('showLoader', false, { root: true });
        if (error && error.response && error.response.status == 403) {
          commit('errorForm', 'Email sending service is not available, please retry later.', { root: true });
          commit('errorFormEmail', 'Email sending service is not available, please retry later.', { root: true });
        } else {
          commit('errorFormEmail', 'API internal error, please retry later.', {
            root: true,
          });
          commit('errorForm', 'API internal error, please retry later.', {
            root: true,
          });
        }
      }
    },
  },
};
