import {
  getvaccineType,
  getVaccinationOfPet,
  addVaccination,
  getVaccination,
  addNutrition,
  deleteVaccination,
  deleteDiagnosis,
  getNutritionPlanOfPet,
  getDashboardOfPet,
  configureBloodDonation,
  getDiagnosisOfPet,
  getTranscriptionOfPet,
  addDiagnosis,
  voiceToText,
  addTranscription,
} from "../services/medicalHistoryService";
import router from "../router";
import axios from "axios";

const state = {
  vaccinations: [],
  nutritions: [],
  diagnosiss: [],
  transcriptions: [],
  allTranscriptions: [],
  dashboard: {},
  text: {},
  vaccineType: [],
  error: "",
};

const getters = {
  getDashboard(state) {
    return state.dashboard;
  },
  getVaccinations(state) {
    return state.vaccinations;
  },
  getNutritions(state) {
    return state.nutritions;
  },
  getDiagnosiss(state) {
    return state.diagnosiss;
  },
  getTranscriptions(state) {
    return state.transcriptions;
  },
  getAllTranscriptions(state) {
    return state.allTranscriptions;
  },
  getVaccineType(state) {
    return state.vaccineType;
  },
  getText(state) {
    return state.text;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setVaccinations(state, vaccinations) {
    state.vaccinations = vaccinations;
  },
  setDashboard(state, dashboard) {
    state.dashboard = dashboard;
  },
  setNutritions(state, nutritions) {
    state.nutritions = nutritions;
  },
  setDiagnosis(state, diagnosis) {
    state.diagnosiss = diagnosis;
  },
  setTranscriptions(state, transcriptions) {
    state.transcriptions = transcriptions;
  },
  setAllTranscriptions(state, transcriptions) {
    state.allTranscriptions = transcriptions;
  },
  setText(state, text) {
    state.text = text;
  },
  setVaccineType(state, vaccinations) {
    state.vaccineType = vaccinations;
  },
  newVaccination(state, vaccination) {
    state.vaccinations = [...state.vaccinations, vaccination];
  },
  newNutrition(state, nutrition) {
    state.nutritions = [...state.nutritions, nutrition];
  },
  newDiagnosis(state, diagnosis) {
    state.diagnosiss = [...state.diagnosiss, diagnosis];
  },

  updVaccination(state, updatedVaccination) {
    const index = state.vaccinations.findIndex(
      (t) => t.id === updatedVaccination.id
    );
    if (index !== -1) {
      state.vaccinations.splice(index, 1, updatedVaccination);
    }
  },
  updNutrition(state, updatedNutrition) {
    const index = state.nutritions.findIndex(
      (t) => t.id === updatedNutrition.id
    );
    if (index !== -1) {
      state.nutritions.splice(index, 1, updatedNutrition);
    }
  },
  updDiagnosis(state, updatedDiagnosis) {
    const index = state.diagnosiss.findIndex(
      (t) => t.id === updatedDiagnosis.id
    );
    if (index !== -1) {
      state.diagnosiss.splice(index, 1, updatedDiagnosis);
    }
  },
  deleteVaccination(state, id) {
    const index = state.vaccinations.findIndex((t) => t.id === id);
    state.vaccinations.splice(index, 1);
  },
  deleteDiagnosis(state, id) {
    const index = state.diagnosiss.findIndex((t) => t.id === id);
    state.diagnosiss.splice(index, 1);
  },
  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async configureBloodDonation({ commit }, data) {
    await configureBloodDonation(data).then(async () => {
      commit("bloodDonorModals", false, { root: true });
      //commit("newVaccination", response.data);
      //router.push("/vaccination");
    });
  },

  async getVaccinationOfPet({ commit }, id) {
    const response = await getVaccinationOfPet(id);

    commit("setVaccinations", response.data);
  },
  async getDashboard({ commit }, id) {
    const response = await getDashboardOfPet(id);

    commit("setDashboard", response.data);
  },
  async getNutritionPlanOfPet({ commit }, id) {
    const response = await getNutritionPlanOfPet(id);

    commit("setNutritions", response.data);
  },
  async getDiagnosisOfPet({ commit }, id) {
    const response = await getDiagnosisOfPet(id);

    commit("setDiagnosis", response.data);
  },
  async SearchTranscript({ commit, state }, searchQuery) {
    let filtered = state.allTranscriptions;
    commit("setTranscriptions", filtered);

    if (searchQuery !== "") {
      filtered = state.transcriptions.filter(
        (item) =>
          item.transcript_text
            .toLowerCase()
            .startsWith(searchQuery.toLowerCase()) ||
          item.title.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          item.date_created.toLowerCase().startsWith(searchQuery.toLowerCase())
      );
      filtered.map((item) => {
        item["title"] = "transcript" + item.id + ".txt";
        item["highlighted"] = false;
      });

      commit("setTranscriptions", filtered);
    }
  },

  async getTranscriptionOfPet({ commit }, id) {
    const response = await getTranscriptionOfPet(id);

    const a = [];

    response.data.map(async (item) => {
      /* const { data: result } = await petRecordById(p.petID);*/

      const file = item.transcript_file;
      axios
        .get(file)
        .then((res) => {
          const fileContent = res.data;
          item["transcript_text"] = fileContent;
          item["title"] = "transcript" + item.id + ".txt";
          item["highlighted"] = false;
          // Process the file content here

          a.push(item);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });
    });

    commit("setTranscriptions", a);
    commit("setAllTranscriptions", a);
  },

  async getVaccineType({ commit }, species) {
    const response = await getvaccineType();
    console.log(response.data.filter((t) => t.species == species));
    commit(
      "setVaccineType",
      response.data.filter((t) => t.species == species)
    );
  },

  async addVaccination({ commit }, vaccination) {
    await addVaccination(vaccination).then(async (response) => {
      commit("newVaccination", response.data);
      router.push("/vaccination");
    });
  },

  async addNutrition({ commit }, nutrition) {
    await addNutrition(nutrition).then(async (response) => {
      commit("newNutrition", response.data);
      commit("showNutritionModals", false, { root: true });
    });
  },

  async addDiagnosis({ commit }, diagnosis) {
    await addDiagnosis(diagnosis)
      .then(async (response) => {
        commit("newDiagnosis", response.data);
        commit("showDiagnosisModals", false, { root: true });
      })
      .catch((error) => {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      });
  },

  async updateNutritionPlan({ commit }, nutrition) {
    await updateNutritionPlan(nutrition)
      .then(async (response) => {
        commit("updNutrition", response.data);
        commit("showEditNutritionModalsState", false, { root: true });
      })
      .catch((error) => {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      });
  },

  async deleteVaccination({ commit, dispatch }, id) {
    await deleteVaccination(id)
      .then(() => {
        commit("deleteVaccination", id);
      })
      .catch((error) => {
        commit("error", "API internal error.");
      });
  },

  async deleteDiagnosis({ commit, dispatch }, id) {
    await deleteDiagnosis(id)
      .then(() => {
        commit("deleteDiagnosis", id);
      })
      .catch((error) => {
        commit("error", "API internal error.");
      });
  },

  async voiceToText({ commit }, voice) {
    console.log(voice);
    await voiceToText(voice)
      .then((response) => {
        commit("setText", response.data);
        commit("publishTranscriptModalsState", true, { root: true });
        commit("transcribeRecordingModalsState", false, { root: true });

        console.log(response);
      })
      .catch((error) => {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
