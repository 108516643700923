import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

const apiEndpoint = apiUrl + '/auth';
const user = null;

const tokenKey = 'token';

export async function login(code) {
  return await axios.get(apiUrl + 'email/login/checkLoginEmail/' + code);
}

export async function loginSP(code) {
  return await axios.get(apiUrl + 'serviceDirectory/claimServiceProviderProfile/' + code);
}

export function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  localStorage.removeItem('role');
  localStorage.removeItem('pet');
  localStorage.removeItem('firstpet');
  localStorage.removeItem(user);
}

export async function getuser(user) {
  alert(user);
  const id = user.email;
  const role = user.groups[0];
  if (role == 1) {
    const { data: user } = await http.get(apiUrl + 'petOwners/petOwner/', { params: { id: id } });
    localStorage.currentUser = user;
  }
  if (role == 2) {
    const { data: user } = await http.get(apiUrl + 'guests/guest/', {
      params: { id: id },
    });
    localStorage.currentUser = user;
  }
  if (role == 3) {
    const { data: user } = await http.get(apiUrl + 'dogBreeders/dogBreeder/', {
      params: { id: id },
    });
    localStorage.currentUser = user;
  }
  if (role == 4) {
    const { data: user } = await http.get(apiUrl + 'serviceProviders/serviceProvider/', {
      params: { id: id },
    });
    localStorage.currentUser = user;
  }
  if (role == 5) {
    const { data: user } = await http.get(apiUrl + 'veterinarians/veterinarian/', {
      params: { id: id },
    });
    localStorage.currentUser = user;
  }
  if (role == 6) {
    const { data: user } = await http.get(apiUrl + 'shelterOrganizations/shelterOrganization/', {
      params: { id: id },
    });
    localStorage.currentUser = user;
  }
}

export function getCurrentUser() {
  try {
    const currentUser = localStorage.currentUser;
    return currentUser;
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem('token');
}

export default {
  login,
  logout,
  getCurrentUser,
  getJwt,
};
