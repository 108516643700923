import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

export function getProgress() {
  return http.get(`${apiUrl}common/registrationProgress/${localStorage.getItem('email')}`);
}

export function getUserReferals() {
  return http.get(`${apiUrl}common/userReferals/${localStorage.getItem('email')}`);
}

//------------------ emergency ------------------
export function listEmergencyContact() {
  return http.get(`${apiUrl}common/listEmergencyContact/${localStorage.getItem('email')}`);
}

export function listFavouriteServices() {
  return http.get(`${apiUrl}common/favouriteServices/${localStorage.getItem('email')}`);
}

export function addToFavouriteServices(email) {
  const req = {
    favouriteType: 1,
    userEmailAddress: localStorage.getItem('email'),
    favouriteServiceEmailAddress: email,
  };

  return http.post(`${apiUrl}common/addToFavourite/`, req);
}

export function confirmEmergencyContact(idContact) {
  //unlock emergency
  return http.post(`${apiUrl}common/confirmEmergencyContact/` + idContact);
}

export function showEmergencyContact(idContact) {
  return http.post(`${apiUrl}common/showEmergencyContact/` + idContact, {});
}

export function hideEmergencyContact(idContact) {
  return http.post(`${apiUrl}common/hideEmergencyContact/` + idContact, {});
}

export function removeFromFavouriteServices(email) {
  const req = {
    userEmailAddress: localStorage.getItem('email'),
    favouriteServiceEmailAddress: email,
  };
  return http.delete(`${apiUrl}common/removeFromFavourite/`, req);
}

//path('confirmEmergencyContact/<idcontact>', views.confirmEmergencyContact),
export function addEmergencyContact(contact) {
  const req = {
    userEmailAddress: localStorage.getItem('email'),
    contactEmailAddress: contact.contactEmailAddress,
    contactphoneNo: contact.contactphoneNo,
    name_label: contact.name,
  };

  return http.post(`${apiUrl}common/emergencyContact/`, req);
}
