import axios from 'axios';
import store from '../store';
import router from '../router';

axios.interceptors.response.use(null, (error) => {
  const expectedError = error.response && error.response.status >= 300 && error.response.status < 500;

  if (!expectedError) {
    store.commit('showLoader', false);
    store.commit('errorForm', 'An unexpected error occurrred.');
    window.scrollTo(0, 0);
  } else {
    store.commit('showLoader', false);
    store.commit('errorForm', error.response.data.message || error.response.data);
    const err = error.response.data.code;
    console.log(err);
    const routeName = router.name;
    if (err == 'token_not_valid' && routeName !== 'PetOverview' && routeName !== 'PetOverview1') {
      store.commit('errorForm', '');
      /*  localStorage.removeItem("token");
      localStorage.removeItem("email");
      localStorage.removeItem("role");
      localStorage.removeItem("pet");
      localStorage.removeItem("firstpet");
      router.push("/join"); */
    }
    window.scrollTo(0, 0);
  }

  return Promise.reject(error);
});

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent, e.g., modify headers
    const jwtToken = localStorage.getItem('token');
    if (jwtToken) {
      config.headers['Authorization'] = `Bearer ${jwtToken}`;
      config.headers['X-Api-Key'] = 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8';
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
