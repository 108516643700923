import {
  addFile,
  addFileBreeder,
  updateFile,
  getFilesOfPet,
  getfileById,
  deletefileById,
} from "../services/medicalHistoryService";

const state = {
  files: [],
  error: "",
};

const getters = {
  getFiles(state) {
    return state.files;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setFiles(state, files) {
    state.files = files;
  },

  newFile(state, file) {
    state.files = [...state.files, file];
  },

  updFile(state, updatedFile) {
    const index = state.files.findIndex((t) => t.id === updatedFile.id);
    if (index !== -1) {
      state.files.splice(index, 1, updatedFile);
    }
  },

  deleteFile(state, id) {
    const index = state.files.findIndex((t) => t.id === id);
    state.files.splice(index, 1);
  },

  error(state, data) {
    return (state.error = data);
  },

  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getFiles({ commit }, id) {
    const response = await getFilesOfPet(id);
    console.log(response);
    if (localStorage.getItem("role") == 1) {
      commit("setFiles", response.data.owner_out_list);
    } else if (localStorage.getItem("role") == 3) {
      console.log(response.data.breeder_out_list);
      commit("setFiles", response.data.breeder_out_list);
    }
  },

  async addFile({ commit }, file) {
    try {
      const response = await addFile(file);
      commit("newFile", response.data);
      commit("confirmFileShareModals", false, { root: true });
    } catch (error) {
      console.log(error);
      commit("errorForm", "API internal error", { root: true });
    }
  },
  async addFileBreeder({ commit }, file) {
    try {
      const response = await addFileBreeder(file);
      console.log(response);
      // response.data.labels = response.data.exam_results;
      commit("newFile", response.data);
      commit("confirmFileShareModals", false, { root: true });
    } catch (error) {
      console.log(error);
      commit("errorForm", "API internal error", { root: true });
    }
  },

  async updateFile({ commit }, file) {
    try {
      await getfileById(file.id).then(async (resp) => {
        console.log(resp.data);
        const res = resp.data;
        res.is_public = file.is_public;
        console.log("*************", res);
        const response = await updateFile(res);
        console.log(response);
        commit("updFile", response.data);
        commit("confirmFileShareModals", false, { root: true });
      });
    } catch (error) {
      console.log(error);
      commit("errorForm", "API internal error", { root: true });
    }
  },
  async deleteFile({ commit, dispatch }, id) {
    console.log(id);
    await deletefileById(id).then(() => {
      commit("deleteFile", id);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
