import http from "./httpService";
import { apiUrl } from "../config.json";

export function getFilesOfPet(id) {
  return http.get(apiUrl + "medicalHistory/filesOfPet/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getfileById(id) {
  return http.get(apiUrl + "medicalHistory/file/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function deletefileById(id) {
  return http.delete(apiUrl + "medicalHistory/file/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function addFile(data) {
  const formData = new FormData();

  formData.append("description", data.description);
  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  data.date_exam ? formData.append("date_exam", data.date_exam) : "";
  formData.append("is_public", data.is_public);
  formData.append("uploaded_file", data.uploaded_file);
  formData.append("userEmailAddress", data.userEmailAddress);
  formData.append("petID", data.petID);

  return http.post(apiUrl + "medicalHistory/file/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function addFileBreeder(data) {
  const formData = new FormData();

  console.log(data.exam_results.length, "--------------");

  formData.append("description", data.description);
  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  data.date_exam ? formData.append("date_exam", data.date_exam) : "";
  formData.append("is_public", data.is_public);
  formData.append("is_official", data.is_official);
  data.exam_results.forEach((item) => formData.append("exam_results", item));
  formData.append("uploaded_file", data.uploaded_file);
  formData.append("userEmailAddress", data.userEmailAddress);
  formData.append("petID", data.petID);

  return http.post(apiUrl + "medicalHistory/file/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function updateFile(data) {
  const formData = new FormData();

  formData.append("description", data.description);
  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  data.date_exam ? formData.append("date_exam", data.date_exam) : "";
  formData.append("is_public", data.is_public);
  formData.append("uploaded_file", data.uploaded_file);
  formData.append("userEmailAddress", data.userEmailAddress);
  formData.append("petID", data.petID);

  return http.put(apiUrl + "medicalHistory/file/", data.id, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getvaccineType() {
  return http.get(apiUrl + "medicalHistory/vaccineType/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function getAppointmentOfpassiveSv() {
  return http.get(apiUrl + "medicalHistory/AppointmentOfpassiveSv/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}



export function configureBloodDonation(data) {
  return http.post(apiUrl + "petRecords/configureBloodDonation/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getVaccinationOfPet(id) {
  return http.get(apiUrl + "medicalHistory/vaccinationOfPet/" + id + "/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function addVaccination(data) {
  const formData = new FormData();

  formData.append("vaccine_type", data.vaccine_type);
  formData.append("vaccine_name", data.vaccine_name);
  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  formData.append("date_applied", data.date_applied);
  data.vaccine_evidence ? formData.append("vaccine_evidence", data.vaccine_evidence) : "";
  data.date_expired ? formData.append("date_expired", data.date_expired) : "";
  formData.append("userEmailAddress", data.userEmailAddress);
  formData.append("petID", data.petID);

  return http.post(apiUrl + "medicalHistory/vaccination/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getVaccination(id) {
  return http.get(apiUrl + "medicalHistory/vaccination/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function getDashboardOfPet(id) {
  return http.get(apiUrl + "medicalHistory/dashboardOfPet/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function deleteVaccination(id) {
  return http.delete(apiUrl + "medicalHistory/vaccination/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getNutritionPlanOfPet(id) {
  return http.get(apiUrl + "medicalHistory/nutritionPlanOfPet/" + id + "/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function getNutritionPlan(id) {
  return http.get(apiUrl + "medicalHistory/nutritionPlan/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function 
addNutrition(data) {
  const req = {
    plan_name: data.plan_name,
    food_brands: data.food_brands,
    ingredients: data.ingredients, //
    feeding_times: data.feeding_times,
    feeding_frequency: data.feeding_frequency,
    feeding_amount: data.feeding_amount,
    measurement: data.measurement,
    userEmailAddress: data.userEmailAddress,
    petID: data.petID,
  };

  return http.post(apiUrl + "medicalHistory/nutritionPlan/", req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function intakeQuestionnaire(petID, req) {
  /*  const req = [
    {
      id: 1,
      custom_answer: null,
      questionnaire: 2,
      choice: 1,
    },
    {
      id: 2,
      custom_answer: "aaaaaa",
      questionnaire: 2,
      choice: 2,
    },
    {
      id: 3,
      custom_answer: null,
      questionnaire: 2,
      choice: 6,
    },
    {
      id: 4,
      custom_answer: null,
      questionnaire: 2,
      choice: 20,
    },
    {
      id: 5,
      custom_answer: "bbbbbb",
      questionnaire: 2,
      choice: 21,
    },
    {
      id: 6,
      custom_answer: "cccccc",
      questionnaire: 2,
      choice: 22,
    },
    {
      id: 7,
      custom_answer: null,
      questionnaire: 2,
      choice: 36,
    },
    {
      id: 8,
      custom_answer: null,
      questionnaire: 2,
      choice: 37,
    },
  ]; */

  return http.post(apiUrl + "medicalHistory/intakeQuestionnaire/" + petID, req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function updateNutritionPlan(id) {
  return http.put(apiUrl + "medicalHistory/nutritionPlan/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}


//--------------------------------- diagnosis ---------------

export function getDiagnosisOfPet(id) {
  return http.get(apiUrl + "medicalHistory/diagnosisOfPet/" + id + "/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function deleteDiagnosis(id) {
  return http.delete(apiUrl + "medicalHistory/diagnosis/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function addDiagnosis(data) {
  const formData = new FormData();
  /*  date_end_disease=models.DateTimeField(null=True)
   is_verified = models.BooleanField(default=False) # -1: n/a | 1: verified | 0: not verified
   is_verified_by = models.EmailField(null=True)
   date_verification =models.DateTimeField(null=True)
   date_created = models.DateTimeField(default=timezone.now) */


  formData.append("disease", data.disease);
  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  formData.append("date_diagnosis", data.date_diagnosis);
  data.transcript_file_id ? formData.append("transcript_file", data.transcript_file_id) : "";
  data.diagnosis_evidence ? formData.append("diagnosis_evidence", data.diagnosis_evidence) : "";
  formData.append("userEmailAddress", data.userEmailAddress);
  formData.append("petID", data.petID);

  return http.post(apiUrl + "medicalHistory/diagnosis/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

//-------------------------------------------------------------- Transcription ----------------------------------
export function getTranscriptionOfPet(id) {
  return http.get(apiUrl + "medicalHistory/transcriptionOfPet/" + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function addTranscription(data) {
  const formData = new FormData();


  formData.append("diagnosis_clinic", data.diagnosis_clinic);
  formData.append("clinic_email_address", data.clinic_email_address);
  formData.append("userEmailAddress", data.userEmailAddress);  
  data.transcript_file ? formData.append("transcript_file", data.transcript_file) : "";
  formData.append("petID", data.petID);

  return http.post(apiUrl + "medicalHistory/publishTranscription/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
export function voiceToText(data) {
  const formData = new FormData();

  formData.append("filename", data.filename);

  return http.post(apiUrl + "medicalHistory/voiceToText/", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}
