import { listPetAsLost, infoReportedPetAsLost } from '../services/panicButtonService';
import { pictureByS } from '../methods/petMethods';

const state = {
  petsaslost: [],
  petsaslostList: [],
  petaslost: {},
  error: '',
};

const getters = {
  getPetsaslost(state) {
    return state.petsaslost;
  },
  getPetaslost(state) {
    return state.petaslost;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setPetsaslost(state, petsaslost) {
    state.petsaslost = petsaslost;
  },
  setPetaslost(state, petaslost) {
    state.petaslost = petaslost;
  },
  newPetsaslost(state, petsaslost) {
    state.petsaslost = [...state.petsaslost, petsaslost];
  },
  updPetsaslost(state, updatedPetsaslost) {
    const index = state.petsaslost.findIndex((t) => t.id === updatedPetsaslost.id);
    if (index !== -1) {
      state.petsaslost.splice(index, 1, updatedPetsaslost);
    }
  },

  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getPetsaslost({ commit }) {
    if (!localStorage.getItem('token')) {
      return;
    }
    const response = await listPetAsLost();
    var result = [];
    var result2 = [];
    result = [...response.data];

    result.map(
      async (item, index) =>
        await infoReportedPetAsLost(item.lost_id, item.petID).then((r) => {
          const z = r.data;
          console.log('r', r.data.lost_info.notification_status, r.data.lost_info.notification_status == false);
          if (r.data.lost_info.notification_status == true) {
            result2.push({
              ...result[index],
              ...z,
              petImage: item.petImage ? item.petImage : pictureByS(item.pet_species),
            });
          }
        })
    );
    console.log('pet as lost: ', result2);
    result.map((item) => {
      /*  const lost_info = a.lost_info;
          const usersToNotify = a.usersToNotify;
          const pet_info = a.pet_info; */
      console.log('a', item);
      /* a.lost_info.notification_status == false
          ? a.usersToNotify.map((c) => cpnsole.log(c))
          : null; */
    });

    commit('setPetsaslost', result2);
  },
  async filterPetsaslost({ commit, dispatch, state }, word) {
    console.log('word : ', word);
    //to do
    /* if (word == "") {
      dispatch("getPetsaslost");
    } else {
      console.log("nnnn : ", state.petsaslost);
      let a = [];
      Object.keys(state.petaslost).map((item) => {
        console.log(item.pet_species, word, item.pet_species == word);
        item.pet_species == word ? a.push(item) : null;
      });
      commit("updPetsaslost", a);
    } */

    //lost_date

    //pet_species
  },
  async getPetaslost({ commit }, id) {
    await infoReportedPetAsLost(id).then((response) => {
      commit('setPetaslost', response);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
