import {
  serviceDirectorySearch,
  serviceDirectoryVetSearch,
  serviceDirectorySuggestedServiceSearch,
} from "../services/serviceDirectoryService";
import moment from "moment";
import router from "../router";

const state = {
  serviceDirectories: [],
  error: "",
};

const getters = {
  getServiceDirectories(state) {
    return state.serviceDirectories;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setServiceDirectories(state, serviceDirectories) {
    state.serviceDirectories = serviceDirectories;
  },
  newServiceDirectory(state, serviceDirectory) {
    state.serviceDirectories = [...state.serviceDirectories, serviceDirectory];
  },

  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getServiceByBusinessName({ commit }, id) {
    try {
      await serviceDirectorySearch(id).then(async (response) => {
        const result1 = response.data;
        const { data: result2 } = await serviceDirectoryVetSearch(id);
        const { data: result3 } = await serviceDirectorySuggestedServiceSearch(
          id
        );
        const result = result1.concat(result2, result3);
        console.log(result /* Object.keys(errors).length */);
        // if (Object.keys(errors).length > 0) {
        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories.length);

        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },

  async getServiceDirectoryType1({ commit }) {
    try {
      await serviceDirectoryVetSearch("").then(async (response) => {
        const result = response.data;

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },
  async getServiceDirectoryType2({ commit }) {
    try {
      await serviceDirectorySearch("").then(async (response) => {
        const result = response.data;
        result.filter(
          (item) =>
            item.serviceType == 8 ||
            item.serviceType == 9 ||
            item.serviceType == 12
        );

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },
  async getServiceDirectoryType3({ commit }) {
    try {
      await serviceDirectorySearch("").then(async (response) => {
        const result = response.data;
        result.filter((item) => item.serviceType == 1 || item.serviceType == 2);

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },
  async getServiceDirectoryType4({ commit }) {
    try {
      await serviceDirectorySearch("").then(async (response) => {
        const result = response.data;
        result.filter(
          (item) =>
            item.serviceType == 3 ||
            item.serviceType == 4 ||
            item.serviceType == 5 ||
            item.serviceType == 10
        );

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },
  async getServiceDirectoryType6({ commit }) {
    try {
      await serviceDirectorySearch("").then(async (response) => {
        const result = response.data;
        result.filter((item) => item.serviceType == 11);

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },

  async getServiceDirectoryType8({ commit }) {
    try {
      await serviceDirectorySearch("").then(async (response) => {
        const result = response.data;
        result.filter((item) => item.serviceType == 7);

        commit("setServiceDirectories", result);
        console.log(state.serviceDirectories, state.serviceDirectories.length);
        router.push("/servicesearchresults");
      });
    } catch (error) {
      if (error && error.response) {
        commit("error", "API internal error.");
      }
    }
  },

  /*   Wellness Services	

Hotels & Day Care	
	
Transportation	
Funerary Services	
Training & Behaviour	

11	Insurance	Misc.	
12	Behaviourist	Training & Behaviour	 */

  async filterServiceDirectory({ commit }, val) {
    try {
      const result = state.serviceDirectories.filter(
        (e) => e.service_24_7 == val
      );
      console.log(result);
      commit("setServiceDirectories", result);
    } catch (error) {
      console.log(error);
    }
  },
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
