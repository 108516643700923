import { getSP, saveSPGallery, getSPGallery, deleteSPGallery, saveSPWorkingHours, getSPWorkingHours } from "../services/serviceProviderService";
import { getSDVet, getSDSuggestedService, getSDServiceProvider } from "../services/serviceDirectoryService";

const state = {
  serviceProvider: {},
  spgallery: [],
  error: "",
};

const getters = {
  getSpgallery(state) {
    return state.spgallery;
  },
  getSP(state) {
    return state.serviceProvider;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setSpgallery(state, spgallery) {
    state.spgallery = spgallery;
  },
  newSpgallery(state, spgallery) {
    state.spgallery = [...state.spgallery, spgallery];
  },
  updSpgallery(state, updatedSpgallery) {
    const index = state.spgallery.findIndex((t) => t.id === updatedSpgallery.id);
    if (index !== -1) {
      state.spgallery.splice(index, 1, updatedSpgallery);
    }
  },
  setSP(state, sp) {
    state.serviceProvider = sp;
  },

  updSP(state, updatedSP) {
    state.serviceProvider = updatedSP;
  },

  deleteSpgallery(state, data) {
    const index = state.spgallery.indexOf(data);
    state.spgallery.splice(index, 1);
  },
  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getSP({ commit }, id) {
    const response = await getSP(id);
    console.log("sd: ", response.data);
    commit("setSP", response.data);
  },
  async getBusinessProfile({ commit }, id) {
    await getSDServiceProvider(id)
      .then(async (response) => {
        await getSPWorkingHours()
          .then(async (r) => {
            response.data.workingHours = r.data;
            commit("setSP", response.data);
          })
          .catch(async () => {
            response.data.workingHours = [];
            commit("setSP", response.data);
          });
        console.log("sp: ", response.data);
      })
      .catch(async () => {
        await getSDVet(id).then(async (response) => {
          await getSPWorkingHours()
            .then(async (r) => {
              response.data.workingHours = r.data;
              commit("setSP", response.data);
            })
            .catch(async () => {
              response.data.workingHours = [];
              commit("setSP", response.data);
            });
          console.log("vet: ", response.data);
        });
      })
      .catch(async () => {
        await getSDSuggestedService(id)
          .then(async (response) => {
            console.log("suggested: ", response.data);
            commit("setSP", response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
  },
  async getSpgallery({ commit }) {
    const response = await getSPGallery(localStorage.getItem("email"));
    console.log("-----ggg :", response.data);
    commit("setSpgallery", response.data);
  },
  async deleteSPGallery({ commit }, id) {
    await deleteSPGallery(id).then((response) => {
      console.log("--delete---", response.data);
      commit("deleteSpgallery", response.data);
    });
  },

  async addSpgalleryComp({ commit }, spgallery) {
    commit("newSpgallery", spgallery);
  },

  async deleteSpgalleryComp({ commit }, spgallery) {
    commit("deleteSpgallery", spgallery);
  },

  async updateSpgalleryComp({ commit }, spgallery) {
    commit("updSpgallery", spgallery);
  },

  async addSpgallery({ commit }, spgallery) {
    await saveSPGallery(spgallery)
      .then((response) => {
        console.log(response.data);

        const item = response.data;
        item.imageurl = null;
        commit("newSpgallery", item);
      })
  },

  async addWorkingHours({ commit }, workingHours) {
    await saveSPWorkingHours(workingHours)
      .then((response) => {
        console.log(response.data);
      })
      
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
