import {
  addAppointment,
  updateAppointment,
  getAppointmentsOfPet,
  getAppointmentByUser,
  getDiaryOfPet,
  addDiaryEntry,
  updateDiaryEntry,
  getComplianceOfPet,
  deleteDiaryEntry,
  getDiaryDashboard,
  getDiaryByUser,
  appointmentRating,
} from "../services/careBlogService";
import router from "../router";
import { getAppointmentOfpassiveSv } from "../services/medicalHistoryService";

const state = {
  appointments: [],
  careBlogs: [],
  diaryDashboard: null,
  compliances: [],
  error: "",
};

const getters = {
  getAppointments(state) {
    return state.appointments;
  },
  getCareBlogs(state) {
    return state.careBlogs;
  },

  getDiaryDashboard(state) {
    return state.diaryDashboard;
  },
  getCompliances(state) {
    return state.compliances;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setAppointments(state, appointments) {
    state.appointments = appointments;
  },
  newAppointment(state, appointment) {
    state.appointments = [...state.appointments, appointment];
  },
  updAppointment(state, updatedAppointment) {
    const index = state.appointments.findIndex(
      (t) => t.id === updatedAppointment.id
    );
    if (index !== -1) {
      state.appointments.splice(index, 1, updatedAppointment);
    }
  },

  setCareBlogs(state, careBlogs) {
    state.careBlogs = careBlogs;
  },

  setDiaryDashboard(state, diaryDashboard) {
    state.diaryDashboard = diaryDashboard;
  },
  setCompliances(state, compliances) {
    state.compliances = compliances;
  },

  newCareBlog(state, careBlog) {
    if (localStorage.getItem("role") == 1)
      //.care_blog_entries
      state.careBlogs = [...state.careBlogs, careBlog];
    else
      state.careBlogs.own_entries = [...state.careBlogs.own_entries, careBlog];
  },
  updCareBlog(state, updatedCareBlog) {
    const index = state.careBlogs.findIndex((t) => t.id === updatedCareBlog.id);
    if (index !== -1) {
      state.careBlogs.splice(index, 1, updatedCareBlog);
    }
  },
  deleteCareBlog(state, data) {
    const index = state.careBlogs.indexOf(data);
    state.careBlogs.splice(index, 1);
  },
  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getAppointments({ commit }, id) {
    const response = await getAppointmentsOfPet(id);
    console.log("-----", response.data);
    commit("setAppointments", response.data);
  },
  async getAppointmentsByUser({ commit }) {
    const response = await getAppointmentByUser(localStorage.getItem("email"));
    console.log("-----", response.data);
    commit("setAppointments", response.data);
  },

  async getAppointmentOfpassiveSv({ commit }) {
    const response = await getAppointmentOfpassiveSv();
    console.log("-----", response.data.appointments);
    commit("setAppointments", response.data.appointments);
  },

  async addAppointment({ commit }, appointment) {
    try {
      const response = await addAppointment(appointment);
      commit("newAppointment", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateAppointment({ commit }, appointment) {
    const response = await updateAppointment(appointment);
    commit("updAppointment", response.data);
  },

  async getCareBlogs({ commit }, id) {
    try {
      const response = await getDiaryOfPet(id);
      console.log(id, "----", response);
      commit("setCareBlogs", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getCareBlogsByUser({ commit }) {
    try {
      const response = await getDiaryByUser();
      console.log(response);
      commit("setCareBlogs", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getDiaryDashboard({ commit }) {
    const response = await getDiaryDashboard();
    commit("setDiaryDashboard", response.data);
  },

  async getCompliances({ commit }, id) {
    const response = await getComplianceOfPet(id);
    commit("setCompliances", response.data);
  },

  async addCareBlog({ commit }, careBlog) {
    await addDiaryEntry(careBlog)
      .then((response) => {
        console.log(response);
        commit("careBlogDiaryModals", false, { root: true });
        commit("newCareBlog", response.data);
        router.push("/careblog" );
       
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("errorForm", error.response.data || "API internal error.", {
            root: true,
          });
        }
      });
  },
  async appointmentRating({ commit }, data) {
    await appointmentRating(data)
      .then((response) => {
        console.log(response);
        //commit("careBlogDiaryModals", false, { root: true });
        //commit("newCareBlog", response.data);
        //console.log("---------------");
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("error", error.response.data || "API internal error.");
        }
      });
  },

  async updateCareBlog({ commit }, careBlog) {
    const response = await updateDiaryEntry(careBlog);
    commit("updCareBlog", response.data);
  },
  async deleteCareBlog({ commit }, data) {
    await deleteDiaryEntry(data.id);
    console.log(data);
    commit("deleteCareBlog", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
