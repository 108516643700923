import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import i18n from "./plugins/i18n";
import "./plugins/directives";
import "./registerServiceWorker";
import VueGeolocation from "vue-browser-geolocation";
/* import {
  Map,
  TileLayer,
  OsmSource,
  Geoloc,
  Style,
  Feature,
  PointGeom,
} from "vuelayers"; */
//import "vuelayers/dist/vuelayers.css"; // needs css-loader
import VueQRCodeComponent from "vue-qrcode-component";

// Register the Vue component
Vue.component("qr-code", VueQRCodeComponent);

import Parse from "parse";

//require("@/store/subscriber");

/* import posthog from "posthog-js";

posthog.init("phc_PKKpneZaLw0ZGU5EiPHc5jo0QN5Rr4T5FKyj004hDGN", {
  api_host: "https://app.posthog.com",
}); */

Parse.initialize("3Sd6Aj4HlinJPuxFr2T5x2RKSMFjmHMSBTXSlR5c", "jG0dmlBc0xAac7jKnlPcpaf5cSRNOMmHaKSMTlSB");
Parse.serverURL = "https://pg-app-xc9bz1wg55u15kfs339rul4dt073fd.scalabl.cloud/1/";

Vue.config.productionTip = false;
Vue.use(VueGeolocation);
//Vue.use(Map);
//Vue.use(Style);
//Vue.use(Feature);
//Vue.use(TileLayer);
//Vue.use(OsmSource);
//Vue.use(Geoloc);

let storeToken = localStorage.getItem("token");
let storeEmail = localStorage.getItem("email");
let storeRole = localStorage.getItem("role");

store.dispatch("auth/attempt", { storeToken, storeEmail, storeRole });

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
