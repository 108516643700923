import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';
import moment from 'moment';
export function getKennelClubs(id) {
  return http.get(apiUrl + 'dogBreeders/KennelClubs');
}
export function deleteDogBreeder(id) {
  return http.delete(apiUrl + 'dogBreeders/dogBreeder/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function saveDogBreeder(data) {
  const formData = new FormData();

  data.image ? formData.append('profileImage', data.image) : '';
  formData.append('provider', data.provider);
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('kennelAddress', data.kennelAddress);
  formData.append('country', data.country);
  formData.append('state', data.state);
  formData.append('city', data.city);
  formData.append('postalCode', data.postalCode);
  formData.append('emailAddress', data.email_address);
  formData.append('phoneNo', data.phone_number);
  formData.append('kennelClub', data.kennelClub);
  formData.append('kennelName', data.kennelName);
  formData.append('kennelRegistrationNo', data.kennelRegistrationNo);
  formData.append('kennelFoundationDate', data.kennelFoundationDate);
  formData.append('zone_long', data.coordinates.lng);
  formData.append('zone_lat', data.coordinates.lat);
  formData.append('news_updates', data.checkboxNews);
  data.referral != '' ? formData.append('referral', data.referral) : '';

  return http.post(apiUrl + 'dogBreeders/dogBreeder/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function updateDogBreeder(profileImage, data, coordinates) {
  const formData = new FormData();
  profileImage ? formData.append('profileImage', profileImage) : null;
  formData.append('firstName', data.firstName);
  formData.append('zone_long', coordinates.lng);
  formData.append('zone_lat', coordinates.lat);
  formData.append('lastName', data.lastName);
  // formData.append("streetAddress", data.streetAddress);
  formData.append('country', data.country);
  // formData.append("state", data.state.id);
  //formData.append("city", "");
  formData.append('postalCode', data.postalCode);
  formData.append('emailAddress', data.emailAddress);
  formData.append('phoneNo', data.phoneNo);
  formData.append('kennelAddress', data.kennelAddress);
  formData.append('kennelClub', data.kennelClub);
  formData.append('kennelName', data.kennelName);
  formData.append('kennelRegistrationNo', data.kennelRegistrationNo);
  data.kennelFoundationDate
    ? formData.append('kennelFoundationDate', moment(data.kennelFoundationDate).format('YYYY-MM-DD'))
    : null;
  return http.put(apiUrl + 'dogBreeders/dogBreeder/' + data.emailAddress, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
