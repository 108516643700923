import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

const apiEndpoint = apiUrl + 'careDiary/';

export function getDiaryEntry(id) {
  return http.get(apiEndpoint + 'diaryEntry/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getDiaryDashboard() {
  return http.get(apiEndpoint + 'dashboard/' + localStorage.getItem('email'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getCoordinatesFromAddress(id) {
  return http.get(apiEndpoint + 'coordinatesFromAddress/' + id);
}

export function getDiaryOfPet(id) {
  return http.get(apiEndpoint + 'careDiaryOfPet/' + id);
}

export function deleteDiaryEntry(id) {
  return http.delete(apiEndpoint + 'diaryEntry/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function addDiaryEntry(data) {
  const formData = new FormData();

  //formData.append("pets", data.pets);

  // formData.append("pets", data.pets);
  data.pets.forEach((item) => formData.append(`pets`, item));
  formData.append('userEmailAddress', data.userEmailAddress);
  formData.append('title', data.title);
  formData.append('description', data.description);
  formData.append('mood', data.mood);
  //formData.append("diary_tags", data.diary_tags);
  data.diary_tags.forEach((item) => formData.append(`diary_tags`, item));
  data.diary == true ? formData.append('diary', data.diary) : '';

  data.video_link ? formData.append('video_link', data.video_link) : '';
  data.voice_link ? formData.append('voice_link', data.voice_link) : '';
  data.image_link ? formData.append('image_link', data.image_link) : '';

  return http.post(apiEndpoint + 'diaryEntry/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function updateDiaryEntry(data) {
  const req = {
    pets: data.pets,
    userEmailAddress: data.userEmailAddress,
    title: data.title,
    description: data.description,
    mood: data.mood,
    diary_tags: data.diary_tags,
    date_last_update: data.date_last_update,
    diary: false,
  };
  return http.put(apiEndpoint + 'diaryEntry/' + data.id, req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

//---------------Appointment ----------------

export function addAppointment(data) {
  const req = {
    description: data.description,
    date_appointment: data.date_appointment,
    time_appointment: data.time_appointment,
    userEmailAddress: data.userEmailAddress,
    petID: data.petID,
    location_appointment_long: data.location_appointment_long,
    location_appointment_lat: data.location_appointment_lat,
  };

  return http.post(apiEndpoint + 'appointment/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function updateAppointment(data) {
  const req = {
    description: data.description,
    date_appointment: data.date_appointment,
    time_appointment: data.time_appointment,
    userEmailAddress: data.userEmailAddress,
    petID: data.petID,
    location_appointment_long: data.location_appointment_long,
    location_appointment_lat: data.location_appointment_lat,
  };
  return http.post(apiEndpoint + 'appointment/' + data.id, req);
}

export function getAppointment(id) {
  return http.get(apiEndpoint + 'appointment/' + id);
}

export function getAppointmentByUser(id) {
  return http.get(apiEndpoint + 'appointmentByUser/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getAppointmentsOfPet(id) {
  return http.get(apiEndpoint + 'appointmentOfPet/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function getDiaryByUser() {
  return http.get(apiEndpoint + 'DiaryOfPetsByUser/' + localStorage.getItem('email'), {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function appointmentRating(data) {
  const req = {
    appointment: data.appointment,
    manners: data.manners, // 1 2 3 4 5
    friendliness: data.friendliness, // 1 2 3 4 5
    energy: data.energy, // 1 2 3 4 5
    compliance: data.compliance, // 1 2 3 4 5
    punctuality: data.punctuality, // 1 2 3 4 5
    payment_issue: data.payment_issue, // YES NO
    attendence_issue: data.attendence_issue, // YES NO
  };

  return http.post(apiEndpoint + 'appointmentRating/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

//-------------------------- comment ----------------
export function hideComment(id) {
  return http.post(apiEndpoint + 'hideComment/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function showComment(id) {
  return http.post(apiEndpoint + 'showComment/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

//------------------------- Favourite -----------------
export function markEntryFavourite(id) {
  return http.post(apiEndpoint + 'markEntryFavourite/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function unmarkEntryFavourite(id) {
  return http.post(apiEndpoint + 'unmarkEntryFavourite/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

//------------------------------------------------

export function getEmoji() {
  return http.get(apiEndpoint + 'EmojiMood');
}

export function addWatchKeywords(data) {
  const req = {
    petID: data.petID,
    userEmailAddress: data.userEmailAddress,
    watched_tags: data.watched_tags,
  };
  return http.post(apiEndpoint + 'watchKeywords/', req);
}

export function getReminders(id) {
  return http.get(apiEndpoint + 'reminders/' + id);
}

export function getComplianceOfPet(id) {
  return http.get(apiEndpoint + 'complianceOfPet/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getDashboard(id) {
  return http.get(apiEndpoint + 'dashboard/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function getSharedCareBlogPets() {
  return http.get(apiEndpoint + 'petsSharedByCareBlog/', {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
