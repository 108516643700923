import http from "./httpService";
import { apiUrl } from "../config.json";
import axios from "axios";

export function newPetRecordStatus(id_transition, petID, userEmailAddress) {
  const data = {
    id_transition: id_transition,
    petID: petID,
    userEmailAddress: userEmailAddress,
  };
  return http.post(apiUrl + "petRecordStatus/newPetRecordStatus/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function sendConfirmIdentityOtp(email_address, phone_number, lng, type) {
  const data = {
    email_address: email_address,
    phone_number: phone_number,
    lang: lng, //EN
    type: type, //4 for archiving pet record
  };
  console.log(data);
  return http.post(apiUrl + "sms/sendConfirmIdentityOtp", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function ConfirmDeceasedStatus(
  id_transition,
  petID,
  userEmailAddress,
  otp,
  dateOfDeath
) {
  const data = {
    id_transition: id_transition,
    petID: petID,
    userEmailAddress: userEmailAddress,
    otp: otp,
    dateOfDeath: dateOfDeath,
  };
  console.log(data);
  return http.post(apiUrl + "petRecordStatus/ConfirmDeceasedStatus/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
    },
  });
}

export function allowedPetRecordStatusTransition(status) {
  return http.get(
    apiUrl + "petRecordStatus/allowedPetRecordStatusTransition/" + status,
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Api-Key": "XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8",
      },
    }
  );
}
export function getStatusByID(status) {
  return http.get(apiUrl + "petRecordStatus/PetRecordStatusByID/" + status);
}
