import http from './httpService';
import axios from 'axios';
import { apiUrl } from '../config.json';

const apiEndpoint = apiUrl + 'breederDirectory/';

export function getDogBreederProfile(id) {
  return http.get(`${apiEndpoint}DogBreederProfile/${id}`);
}

export function getBreeder(breed_name) {
  /* ?active_breeds_contains=${breed_name} */
  return http.get(`${apiEndpoint}breeder?active_breeds_contains=${breed_name}`);
}

export function getBreederByWB(val) {
  if (val == true) {
    return http.get(`${apiEndpoint}breeder?working_breeder=True`);
  } else {
    return http.get(`${apiEndpoint}breeder?working_breeder=False`);
  }
}

export function getBreederBySB(val) {
  if (val == true) {
    return http.get(`${apiEndpoint}breeder?show_breeder=True`);
  } else {
    return http.get(`${apiEndpoint}breeder?show_breeder=False`);
  }
}

export function getDogBreeder(id) {
  return http.get(`${apiUrl}dogBreeders/dogBreeder/${id}`);
}

export function updateDogBreederProfile(dataProfile, data) {
  const formData = new FormData();

  data.profileImage ? formData.append('profileImage', profileImage) : '';
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('kennelAddress', data.kennelAddress);
  formData.append('state', data.state);
  formData.append('country', data.country);
  formData.append('kennelFoundationDate', data.kennelFoundationDate);
  formData.append('kennelRegistrationNo', data.kennelRegistrationNo);
  formData.append('kennelName', data.kennelName);
  formData.append('phoneNo', data.phoneNo);
  formData.append('postalCode', data.postalCode);
  formData.append('city', data.city);
  formData.append('emailAddress', data.emailAddress);
  formData.append('kennelClub', data.kennelClub);

  formData.append('profile_streetAddress', dataProfile.profile_streetAddress);
  formData.append('profile_country', dataProfile.profile_country);
  formData.append('profile_state', dataProfile.profile_state);
  formData.append('profile_city', dataProfile.profile_city);
  formData.append('profile_postalCode', dataProfile.profile_postalCode);
  formData.append('profile_emailAddress', dataProfile.profile_emailAddress);
  formData.append('profile_phoneNo', dataProfile.profile_phoneNo);
  formData.append('discoverable', dataProfile.discoverable ? 1 : 0);

  return http.put(`${apiUrl}dogBreeders/dogBreeder/${dataProfile.profile_emailAddress}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getSectionsByGroups(id) {
  return http.get(`${apiEndpoint}sectionsByGroups`, {});
}

export function getBreederProfileByQr(id) {
  return http.get(`${apiEndpoint}BreederProfileByQr/${id}`);
}
