import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

const apiEndpoint = apiUrl + 'common/';

export function getContact(id) {
  return http.get(apiEndpoint + 'contact/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getContactByUser(id) {
  return http.get(apiEndpoint + 'contactsOfUser/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getcontactDetails(id) {
  return http.get(apiEndpoint + 'contactDetails/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function deleteContact(id) {
  return http.delete(apiEndpoint + 'contact/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function addContact(data) {
  const req = {
    userEmailAddress: localStorage.getItem('email'),
    whatsapp: data.whatsapp,
    facebook: data.facebook,
    instagram: data.instagram,
    telegram: data.telegram,
    viber: data.viber,
    line: data.line,
  };
  return http.post(apiEndpoint + 'contactDetails/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function contactRequest(data) {
  const req = {
    userEmailAddress: data.userEmailAddress,
    contactEmailAddress: data.contactEmailAddress,
    contactphoneNo: data.contactphoneNo,
    status: 1,
  };
  return http.post(apiEndpoint + 'contactRequest/', req, {
    headers: {
      /* Authorization: `Bearer ${localStorage.getItem("token")}`, */
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
export function addNewContact(data) {
  const req = {
    userEmailAddress: data.userEmailAddress,
    contactEmailAddress: data.contactEmailAddress,
    contactphoneNo: data.contactphoneNo,
    name_label: data.name_label,
    emergency_contact: data.emergency_contact,
    emergency_status: data.emergency_status,
    emergency_show_on_profile: data.emergency_show_on_profile,
    referral: data.referral,
  };
  return http.post(apiEndpoint + 'contact/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function contactAcceptOrDecline(data) {
  // accept or decline
  const req = {
    status: data.status, //2 accept 3 decline
  };
  return http.put(apiEndpoint + 'contactRequestResponse/' + data.id, req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function sharepetsWithContact(data) {
  //sharing yes
  const req = {
    userEmailAddress: data.userEmailAddress,
    contactEmailAddress: data.contactEmailAddress,
  };
  console.log(req);
  return http.post(apiEndpoint + 'sharepetsWithContact/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function revokeSharepetsWithContact(data) {
  //sharing no
  const req = {
    userEmailAddress: data.userEmailAddress,
    contactEmailAddress: data.contactEmailAddress,
  };
  return http.post(apiEndpoint + 'revokeSharepetsWithContact/', req, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}

export function getPendingContactRequestsOfUser(id) {
  //user
  return http.get(apiEndpoint + 'pendingContactRequestsOfUser/' + id, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'X-Api-Key': 'XVxQ33TQ.WgA3FxPVSs62W1uI7AEhGyRBEPlqLvO8',
    },
  });
}
