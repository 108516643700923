import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

const apiEndpoint = apiUrl + 'healthLog/';

function parasiteTraitmentTrackingUrl(id) {
  return `${apiEndpoint}parasiteTraitmentTracking/${id}`;
}

function medicationTrackingUrl(id) {
  return `${apiEndpoint}MedicationTracking/${id}`;
}

function symptomTrackerUrl(id) {
  return `${apiEndpoint}symptomTracker/${id}`;
}

function pastIllnessUrl(id) {
  return `${apiEndpoint}pastIllness/${id}`;
}

export function getSymptoms() {
  return http.get(apiEndpoint + 'symptoms/');
}

export function getVeterinaryMedications() {
  return http.get(apiEndpoint + 'veterinaryMedications/');
}

export function getParasiteTraitment() {
  return http.get(apiEndpoint + 'parasiteTraitment/');
}

export function savePastIllness(data) {
  const req = {
    symptoms: data.symptoms,
    start_illness: data.start_illness,
    end_illness: data.end_illness,
    userEmailAddress: localStorage.getItem('email'),
    petID: data.petID,
  };

  if (data.id) {
    const body = { ...data };
    delete body.id;
    return http.put(pastIllnessUrl(data.id), body);
  }
  return http.post(`${apiEndpoint}pastIllness/`, req);
}

export function getPastIllness(id) {
  return http.get(pastIllnessUrl(id));
}

export function deletePastIllness(id) {
  return http.delete(pastIllnessUrl(id));
}

export function saveSymptomTracker(data) {
  const req = {
    symptoms: data.symptoms,
    custom_symptoms: data.custom_symptoms,
    userEmailAddress: localStorage.getItem('email'),
    petID: data.petID,
  };
  data.notes ? (req.notes = data.notes) : null;
  data.weight ? (req.weight = data.weight) : null;

  if (data.id) {
    const body = { ...data };
    delete body.id;
    return http.put(symptomTrackerUrl(data.id), body);
  }
  return http.post(`${apiEndpoint}symptomTracker/`, req);
}

export function getSymptomTracker(id) {
  return http.get(symptomTrackerUrl(id));
}

export function deleteSymptomTracker(id) {
  return http.delete(symptomTrackerUrl(id));
}

export function saveWeeklyCheck(data) {
  const formData = new FormData();

  data.top_view ? formData.append('top_view', data.top_view) : '';
  data.side_view ? formData.append('side_view', data.side_view) : '';
  formData.append('poop', data.poop);
  formData.append('other_significance', data.other_significance);
  formData.append('weight', data.weight);
  formData.append('userEmailAddress', localStorage.getItem('email'));
  formData.append('petID', data.petID);

  return http.post(`${apiEndpoint}weeklyCheck/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function getWeeklyCheck(id) {
  return http.get(`${apiEndpoint}weeklyCheck/${id}`);
}

export function deleteWeeklyCheck(id) {
  return http.delete(`${apiEndpoint}weeklyCheck/${id}`);
}

export function getHealthVisualized(petID) {
  return http.get(`${apiEndpoint}healthVisualized/${petID}`);
}

export function getDiseaseHistory() {
  return http.get(`${apiEndpoint}diseaseHistory/`);
}

export function saveMedicationTracking(data) {
  const req = {
    medication: data.medication,
    first_intake: data.first_intake,
    frequency: data.frequency,
    frequency_times: data.frequency_times,
    notification: data.notification,
    userEmailAddress: localStorage.getItem('email'),
    petID: data.petID,
    duration_in_days: data.duration_in_days,
    duration_forever: data.duration_forever,
    brand_dosage: data.brand_dosage,
    last_notification: data.last_notification,
  };

  if (data.id) {
    const body = { ...data };
    delete body.id;
    return http.put(medicationTrackingUrl(data.id), body);
  }

  return http.post(`${apiEndpoint}MedicationTracking/`, req);
}

export function getMedicationTracking(id) {
  return http.get(medicationTrackingUrl(id));
}

export function deleteMedicationTracking(id) {
  return http.delete(medicationTrackingUrl(id));
}

export function getMedicationTrackingOfPet(petID) {
  return http.get(apiEndpoint + 'MedicationTrackingOfPet/' + petID);
}

export function saveParasiteTraitmentTracking(data) {
  const req = {
    treatment: data.treatment,
    first_intake: data.first_intake,
    frequency: data.frequency,
    duration_in_days: data.duration_in_days,
    duration_forever: data.duration_forever,
    brand_dosage: data.brand_dosage,
    notification: data.notification,
    userEmailAddress: localStorage.getItem('email'),
    petID: data.petID,
    last_notification: data.last_notification,
  };

  if (data.id) {
    const body = { ...data };
    delete body.id;
    return http.put(parasiteTraitmentTrackingUrl(data.id), body);
  }

  return http.post(`${apiEndpoint}/parasiteTraitmentTracking/`, req);
}

export function getParasiteTraitmentTracking(id) {
  return http.get(parasiteTraitmentTrackingUrl(id));
}

export function deleteParasiteTraitmentTracking(id) {
  return http.delete(parasiteTraitmentTrackingUrl(id));
}

export function saveCustomSymptom(data) {
  const req = {
    name: data.name,
    userEmailAddress: localStorage.getItem('email'),
  };
  return http.post(apiEndpoint + 'customSymptom/', req);
}

export function getCustomSymptom(id) {
  return http.get(apiEndpoint + 'customSymptom/' + id);
}

export function getPreviouslyUsedCustomSymptom(petID) {
  return http.get(apiEndpoint + 'previouslyUsedCustomSymptom/' + petID);
}

export function deletePreviouslyUsedCustomSymptom(id) {
  return http.delete(apiEndpoint + 'previouslyUsedCustomSymptom/' + id);
}

export function getParasiteTraitmentINcareRoutineActivities(petID) {
  return http.get(apiEndpoint + 'parasiteTraitmentINcareRoutineActivities/' + petID);
}

export function getMedicationINcareRoutineActivities(id) {
  return http.get(apiEndpoint + 'MedicationINcareRoutineActivities/' + id);
}

//parasiteTraitmentCheckAsDone/<id>
//MedicationCheckAsDone/<id>

export function activateNotification(data, reminder) {
  const req = {
    name: data.name, //SYMPTOM TRACKER / WEEKLY CHECK
    frequency: data.frequency, //daily weekly  monthly quarterly
    reminder: true, //FIXME: false ?
    userEmailAddress: localStorage.getItem('email'),
    petID: data.petID,
  };

  return reminder == true
    ? http.post(apiUrl + 'common/activate_notification', req)
    : http.post(apiUrl + 'common/deactivate_notification', req);
}
