import {
  getHealthVisualized,
  saveWeeklyCheck,
  getSymptoms,
  saveSymptomTracker,
  savePastIllness,
  getMedicationTrackingOfPet,
  saveMedicationTracking,
  getPreviouslyUsedCustomSymptom,
} from "../services/healthLogService";
import router from "../router";

const state = {
  healths: {},
  medicationTracking: [],
  symptoms: [],
  prevUsedCustomSymptom: [],
  error: "",
};

const getters = {
  getHealths(state) {
    return state.healths;
  },
  getMedicationTracking(state) {
    return state.medicationTracking;
  },
  getSymptoms(state) {
    return state.symptoms;
  },
  getPrevUsedCustomSymptom(state) {
    return state.prevUsedCustomSymptom;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setHealths(state, healths) {
    state.healths = healths;
  },
  newHealth(state, health) {
    state.healths = [...state.healths, health];
  },
  updHealth(state, updatedHealth) {
    const index = state.healths.findIndex((t) => t.id === updatedHealth.id);
    if (index !== -1) {
      state.healths.splice(index, 1, updatedHealth);
    }
  },
  setMedicationTracking(state, medicationTracking) {
    state.medicationTracking = medicationTracking;
  },
  newMedicationTracking(state, medicationTracking) {
    state.medicationTracking = [...state.medicationTracking, medicationTracking];
  },
  updMedicationTracking(state, updatedMedicationTracking) {
    const index = state.medicationTracking.findIndex((t) => t.id === updatedMedicationTracking.id);
    if (index !== -1) {
      state.medicationTracking.splice(index, 1, updatedMedicationTracking);
    }
  },
  setSymptoms(state, symptoms) {
    state.symptoms = symptoms;
  },
  setPrevUsedCustomSymptom(state, symptoms) {
    state.prevUsedCustomSymptom = symptoms;
  },
  newSymptom(state, symptom) {
    state.symptoms = [...state.symptoms, symptom];
  },
  newPrevUsedCustomSymptom(state, symptom) {
    state.prevUsedCustomSymptom = [...state.prevUsedCustomSymptom, symptom];
  },
  updSymptom(state, updatedSymptom) {
    const index = state.symptoms.findIndex((t) => t.id === updatedSymptom.id);
    if (index !== -1) {
      state.symptoms.splice(index, 1, updatedSymptom);
    }
  },
  updPrevUsedCustomSymptom(state, updatedSymptom) {
    const index = state.prevUsedCustomSymptom.findIndex((t) => t.id === updatedSymptom.id);
    if (index !== -1) {
      state.prevUsedCustomSymptom.splice(index, 1, updatedSymptom);
    }
  },

  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getHealthVisualized({ commit }, id) {
    await getHealthVisualized(id)
      .then((response) => {
        commit("setHealths", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getMedicationTrackingOfPet({ commit }, id) {
    await getMedicationTrackingOfPet(id)
      .then((response) => {
        console.log(response.data);
        commit("setMedicationTracking", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async getSymptoms({ commit }) {
    await getSymptoms()
      .then((response) => {
        console.log(response.data);
        commit("setSymptoms", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async getPrevUsedCustomSymptom({ commit }, id) {
    await getPreviouslyUsedCustomSymptom(id)
      .then((response) => {
        console.log("response", response.data.length, response.data);
        commit("setPrevUsedCustomSymptom", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async searchSymptoms({ commit, dispatch, state }, word) {
    if (word == "") {
      dispatch("getSymptoms");
    } else {
      let a = [];
      state.symptoms.map((item) => {
        item.name == word ? a.push(item) : null;
      });

      commit("setSymptoms", a);
    }
  },

  async addWeeklyCheck({ commit }, careBlog) {
    await saveWeeklyCheck(careBlog)
      .then((response) => {
        router.push("/healthlogoverview/visualizedhealth");
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("error", error.response.data || "API internal error.");
        }
      });
  },

  async addSymptomTracker({ commit }, data) {
    await saveSymptomTracker(data)
      .then((response) => {
        commit("symptomTrackerModals", false, { root: true });
        commit("pastIllnessModals", false, { root: true });
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("error", error.response.data || "API internal error.");
        }
      });
  },
  async addMedicationTracking({ commit }, data) {
    await saveMedicationTracking(data)
      .then((response) => {
        router.push("/healthlogoverview/medication");
        //commit("symptomTrackerModals", false, { root: true });
        //commit("pastIllnessModals", false, { root: true });
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("error", error.response.data || "API internal error.");
        }
      });
  },
  async addPastIllness({ commit }, data) {
    await savePastIllness(data)
      .then((response) => {
        commit("symptomTrackerModals", false, { root: true });
        commit("pastIllnessModals", false, { root: true });
      })
      .catch((error) => {
        console.log(error);
        if (error && error.response) {
          console;
          commit("error", error.response.data || "API internal error.");
        }
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
