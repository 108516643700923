import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import axios from 'axios';
import { apiUrl } from '../config.json';
import router from '../router';
import { pictureBySpecies } from '../methods/petMethods';

import {
  petsByUser,
  petRecordById,
  savePetRecord,
  savePetGallery,
  updatePetGallery,
  updatePetRecord,
  savePetDocument,
  deletePetRecord,
  getPetProfile,
} from '../services/petRecordService';
import { ConfirmDeceasedStatus } from '../services/petRecordStatusService';
import { newPetRecordStatus, allowedPetRecordStatusTransition } from '../services/petRecordStatusService';
import { incognitoModeOn, incognitoModeOff } from '../services/sharingService';
import { getPetStatusNameById } from '../methods/petMethods';

export default {
  namespaced: true,

  state: {
    petRecordsState: [],
    petProfileState: {},
    transitionsState: [],
    error: '',
  },

  getters: {
    getPetRecords(state) {
      return state.petRecordsState;
    },
    getTransitions(state) {
      return state.transitionsState;
    },
    getPetProfile(state) {
      return state.petProfileState;
    },
    getPetRecord(state) {
      return state.petRecordState;
    },
    getError(state) {
      return state.error;
    },
  },

  mutations: {
    setPetRecords(state, data) {
      state.petRecordsState = data;
    },
    setTransitions(state, data) {
      state.transitionsState = data;
    },
    setPetProfile(state, data) {
      state.petProfileState = data;
    },
    newPetRecord(state, data) {
      data['color'] = '#F89520';
      data['type'] = 1;
      data['checked'] = false;

      state.petRecordsState = [...state.petRecordsState, data];
    },

    async updPetRecord(state, data) {
      const index = state.petRecordsState.findIndex((p) => p.petID === data.petID);

      if (index !== -1) {
        const pet = state.petRecordsState.filter((p) => data.petID === p.petID);
        console.log(pet);
        pet[0].current_status = data.current_status;
        pet[0].current_status_name = getPetStatusNameById(data.current_status);
        console.log(pet[0]);
        state.petRecordsState.splice(index, 1, pet[0]);
        localStorage.setItem('pet', JSON.stringify(pet[0]));
        console.log(state.petRecordsState);
      }
    },
    async updPetRecordDeceased(state, petID) {
      const index = state.petRecordsState.findIndex((p) => p.petID === petID);

      if (index !== -1) {
        const pet = state.petRecordsState.filter((p) => petID === p.petID);

        pet[0].type = 4;

        state.petRecordsState.splice(index, 1, pet[0]);
      }
    },

    async updPetRecord2(state, data) {
      const index = state.petRecordsState.findIndex((p) => p.petID === data.petID);

      if (index !== -1) {
        state.petRecordsState.splice(index, 1, data);
      }
    },

    deletePetRecord(state, data) {
      state.petRecordsState = state.petRecordsState.filter((p) => data.petID !== p.petID);
    },
    setPetRecord(state, data) {
      state.petRecordState = data;
    },
    error(state, data) {
      return (state.error = data);
    },
  },

  actions: {
    async getTransitions({ commit }, id) {
      try {
        const { data: result } = await allowedPetRecordStatusTransition(id);
        let transition = [];
        result.map((tran) => {
          //v-if="item.nextStatusId != 102 && role !== 3"
          if (localStorage.getItem('role') != 3 && tran.next_status.id == 102) {
          } else {
            transition.push({
              id: tran.id,
              nextStatusId: tran.next_status.id,
              nextStatusName: tran.next_status.name,
            });
          }
        });
        commit('setTransitions', transition);
      } catch (error) {
        commit('error', 'API internal error.');
      }
    },

    async getPetProfile({ commit }, id) {
      //commit("showLoader", true, { root: true });
      await getPetProfile(id)
        .then((result) => {
          console.log('profile:', result);
          commit('setPetProfile', result.data);
          commit('showLoader', false, { root: true });
        })
        .catch((error) => {
          console.log(error);
          commit('showLoader', false, { root: true });
          commit('error', 'API internal error.');
        });
    },

    async getPetRecords({ commit }, data) {
      if (!localStorage.getItem('token')) {
        commit('setPetRecords', []);
        return;
      }
      const { data: result } = await petsByUser(data);

      const owned_pets = result.owned_pets;
      const coowned_pets = result.coowned_pets;
      const pets_shared_with_me = result.pets_shared_with_me;
      const owned_pets_deceased = result.owned_pets_deceased;
      const pets_shared_with_me_deceased = result.pets_shared_with_me_deceased;

      const pets = [];

      owned_pets.map(async (p) => {
        /*  const { data: result } = await petRecordById(p.petID);*/
        const petRecord = p.pet;

        petRecord['profile_picture'] = p.profile_picture ? p.profile_picture : pictureBySpecies(petRecord);
        petRecord['petGallery'] = p.pet_gallery.filter((p) => p.primary_picture != 1);
        petRecord['unverifiedHealthStatus'] = p.pet_unverifiedHealthStatus;
        petRecord['doc'] = p.pet_docs[0];
        petRecord['current_status_name'] = getPetStatusNameById(petRecord.current_status);
        petRecord['workingTitle'] = petRecord.workingTitle.toString();
        petRecord['showTitle'] = petRecord.showTitle.toString();
        petRecord['color'] = '#F89520';
        petRecord['type'] = 1;
        petRecord['checked'] = false;
        petRecord['private'] = p.private;
        pets.push(petRecord);
      });

      coowned_pets.map(async (p) => {
        /*  const { data: result } = await petRecordById(p.petID);*/
        const petRecord = p.pet;

        petRecord['profile_picture'] = p.profile_picture ? p.profile_picture : pictureBySpecies(petRecord);
        petRecord['petGallery'] = p.pet_gallery.filter((p) => p.primary_picture != 1);
        petRecord['unverifiedHealthStatus'] = p.pet_unverifiedHealthStatus;
        petRecord['doc'] = p.pet_docs;
        petRecord['workingTitle'] = petRecord.workingTitle.toString();
        petRecord['showTitle'] = petRecord.showTitle.toString();
        petRecord['current_status_name'] = getPetStatusNameById(petRecord.current_status);
        petRecord['color'] = '#0AC0CB';
        petRecord['type'] = 2;
        petRecord['checked'] = false;
        petRecord['private'] = p.private;
        pets.push(petRecord);
      });

      pets_shared_with_me.map(async (p) => {
        /* const { data: result } = await petRecordById(p.petID);*/
        const petRecord = p.pet;

        petRecord['profile_picture'] = p.profile_picture ? p.profile_picture : pictureBySpecies(petRecord);
        petRecord['petGallery'] = p.pet_gallery.filter((p) => p.primary_picture != 1);
        petRecord['doc'] = p.pet_docs;
        petRecord['current_status_name'] = getPetStatusNameById(petRecord.current_status);
        petRecord['color'] = '';
        petRecord['type'] = 3;
        petRecord['checked'] = false;
        petRecord['private'] = p.private;
        pets.push(petRecord);
      });

      owned_pets_deceased.map(async (p) => {
        /*  const { data: result } = await petRecordById(p.petID);*/
        const petRecord = p.pet;

        petRecord['profile_picture'] = p.profile_picture ? p.profile_picture : pictureBySpecies(petRecord);
        petRecord['petGallery'] = p.pet_gallery.filter((p) => p.primary_picture != 1);
        petRecord['doc'] = p.pet_docs;
        petRecord['current_status_name'] = getPetStatusNameById(petRecord.current_status);
        petRecord['color'] = '';
        petRecord['type'] = 4;
        petRecord['checked'] = false;
        petRecord['private'] = p.private;
        pets.push(petRecord);
      });

      pets_shared_with_me_deceased.map(async (p) => {
        /*  const { data: result } = await petRecordById(p.petID);*/
        const petRecord = p.pet;

        petRecord['profile_picture'] = p.profile_picture ? p.profile_picture : pictureBySpecies(petRecord);
        petRecord['petGallery'] = p.pet_gallery.filter((p) => p.primary_picture != 1);
        petRecord['doc'] = p.pet_docs;
        petRecord['current_status_name'] = getPetStatusNameById(petRecord.current_status);
        petRecord['color'] = '';
        petRecord['type'] = 5;
        petRecord['checked'] = false;
        pets.push(petRecord);
      });

      commit('setPetRecords', pets);
      /*  setTimeout(function () {
        }, 5000); */
      commit('showLoader', false, { root: true });
    },

    async updatePrivateMode({ commit }, data) {
      if (data.check == true) {
        await incognitoModeOn(data.pet.petID).then(() => {
          data.pet['private'] = true;
          localStorage.setItem('pet', JSON.stringify(data.pet));
          commit('updPetRecord2', data.pet);
        });
      } else {
        await incognitoModeOff(data.pet.petID).then(() => {
          data.pet['private'] = false;
          commit('updPetRecord2', data.pet);
        });
      }
    },

    async updatePetStatus({ commit }, data) {
      try {
        await newPetRecordStatus(data.trans, data.petID, data.email).then((result) => {
          commit('updPetRecord', result.data);
          commit('petStatusModals', false, { root: true });
        });
      } catch (error) {
        commit('error', 'API internal error.');
      }
    },
    async updatePetStatusDeceased({ commit }, data) {
      try {
        await ConfirmDeceasedStatus(data.trans, data.petID, data.email, data.otp, data.dateOfDeath).then((result) => {
          commit('updPetRecordDeceased', result.data.petID);
          commit('petStatusModals', false, { root: true });
          commit('petConfirmeIdentityModals', false, { root: true });
        });
      } catch (error) {
        commit('error', 'API internal error.');
      }
    },

    async addPetRecord({ commit }, data) {
      let aa = [];
      if (data.petProfileImg && data.petProfileImg.size > 5000000) {
        commit('error', 'The uploaded profile picture is too large. The maximum size supported by the server is 5 MB');
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else {
        if (data.petData.petIdentificationImage && data.petData.petIdentificationImage.size > 5000000) {
          commit(
            'error',
            'The uploaded pet identification file is too large. The maximum size supported by the server is 5 MB'
          );
          window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
          await savePetRecord(
            data.petData,
            data.userEmailAddress,
            data.vetEmailAddress,
            data.unverifiedHealthStatus,
            data.petProfileImg,
            data.petGallery
          )
            .then(async (response) => {
              /*  
                petRecord["doc"] = p.pet_docs[0];
                pets.push(petRecord); */
              const pet = response.data.data_petRecord;

              pet['color'] = '#F89520';

              pet.profile_picture = aa.filter((f) => f.primary_picture == 1)[0]
                ? aa.filter((f) => f.primary_picture == 1)[0].petImage
                : pictureBySpecies(pet);

              //pet.type = response.data.data_petOwnership.;
              pet.checked = false;

              pet.petGallery = aa.filter((f) => f.primary_picture != 1);
              pet.current_status_name = getPetStatusNameById(100);

              commit('newPetRecord', pet);
              router.push('/petoverview/news');
            })
            .catch((error) => {
              if (error && error.response) {
                if (error.code == 400) {
                  const a = error.response.data.valueError;
                  commit('error', a);
                } else if (error.response.status == 409) {
                  const a = error.response.data;
                  commit('error', a);
                } else {
                  if (
                    error.response.data[0].petImage ||
                    error.response.data[1].petImage ||
                    error.response.data[2].petImage ||
                    error.response.data[3].petImage ||
                    error.response.data[4].petImage ||
                    error.response.data[5].petImage ||
                    error.response.data[6].petImage ||
                    error.response.data[7].petImage ||
                    error.response.data[8].petImage ||
                    error.response.data[9].petImage
                  ) {
                    commit(
                      'error',
                      error.response.data[0].petImage[0] ||
                        error.response.data[1].petImage[1] ||
                        error.response.data[2].petImage[2] ||
                        error.response.data[3].petImage[3] ||
                        error.response.data[4].petImage[4] ||
                        error.response.data[5].petImage[5] ||
                        error.response.data[6].petImage[6] ||
                        error.response.data[7].petImage[7] ||
                        error.response.data[8].petImage[8] ||
                        error.response.data[9].petImage[9]
                    );
                  } else {
                    commit('error', 'Save error.');
                  }
                }

                window.scrollTo(0, 0);
              }
            });
        }
      }
    },

    async getPetRecord({ commit }, data) {
      try {
        const { data: result } = await petRecordById(data);

        commit('setPetRecord', result.petRecord);
      } catch (error) {
        console.log(error);
      }
    },
    //------------------------------------ update pet record -------------------------------------------------------------------
    async updatePetRecord({ commit }, data) {
      let aa = [];

      await updatePetRecord(
        data.petData,
        data.userEmailAddress,
        data.vetEmailAddress,
        data.unverifiedHealthStatus,
        data.petProfileImg,
        data.petGallery
      ).then(async (response) => {
        console.log('response', response);
        /*  
            petRecord["doc"] = p.pet_docs[0];
            pets.push(petRecord); */
        const pet = response.data.petRecord;

        pet['color'] = '#F89520';

        pet.profile_picture = aa.filter((f) => f.primary_picture == 1)[0]
          ? aa.filter((f) => f.primary_picture == 1)[0].petImage
          : pictureBySpecies(pet);

        //pet.type = response.data.data_petOwnership.;
        pet.checked = false;

        pet.petGallery = aa.filter((f) => f.primary_picture != 1);
        pet.current_status_name = getPetStatusNameById(100);

        commit('newPetRecord', pet);
        //router.push("/petoverview/news");
      });
    },

    async deletePetRecord({ commit, dispatch }, data) {
      await deletePetRecord(data.petID)
        .then(() => {
          commit('deletePetRecord', data);
          commit('showDeleteModals', false, { root: true });
          router.push('/petoverview/news');
        })
        .catch((error) => {
          commit('error', error);
        });
    },
  },
};
