import { petsByUser, petRecordById } from "../services/petRecordService";

export function petPicture(pet) {
  if (pet.petGallery && pet.petGallery.length > 0) {
    let p = pet.petGallery.filter((g) => g.primary_picture == 1);
    return p[0].petImage;
  } else {
    if (pet.species.id == 1)
      return require(`@/assets/images/peticons/dog_icon.png`);
    if (pet.species.id == 2)
      return require(`@/assets/images/peticons/cat_icon.png`);
    if (pet.species.id == 3)
      return require(`@/assets/images/peticons/ferret_icon.png`);
    if (pet.species.id == 4)
      return require(`@/assets/images/peticons/bird_icon.png`);
    if (pet.species.id == 5)
      return require(`@/assets/images/peticons/other_icon.png`);
  }
}

export function pictureBySpecies(pet) {
  if (pet.species == 1) return require(`@/assets/images/peticons/dog_icon.png`);
  if (pet.species == 2) return require(`@/assets/images/peticons/cat_icon.png`);
  if (pet.species == 3)
    return require(`@/assets/images/peticons/ferret_icon.png`);
  if (pet.species == 4)
    return require(`@/assets/images/peticons/bird_icon.png`);
  if (pet.species == 5)
    return require(`@/assets/images/peticons/other_icon.png`);
}

export function pictureByS(sp) {
  if (sp == 1) return require(`@/assets/images/peticons/dog_icon.png`);
  if (sp == 2) return require(`@/assets/images/peticons/cat_icon.png`);
  if (sp == 3) return require(`@/assets/images/peticons/ferret_icon.png`);
  if (sp == 4) return require(`@/assets/images/peticons/bird_icon.png`);
  if (sp == 5) return require(`@/assets/images/peticons/other_icon.png`);
}
export function pictureByNameSpecies(pet) {
  console.log(pet);
  if (pet.species == "dog")
    return require(`@/assets/images/peticons/dog_icon.png`);
  if (pet.species == "cat")
    return require(`@/assets/images/peticons/cat_icon.png`);
  if (pet.species == "ferret")
    return require(`@/assets/images/peticons/ferret_icon.png`);
  if (pet.species == "bird")
    return require(`@/assets/images/peticons/bird_icon.png`);
  if (pet.species == "other")
    return require(`@/assets/images/peticons/other_icon.png`);
}

export function nameOfPet(species) {
  if (species == 1) return "Dog";
  if (species == 2) return "Cat";
  if (species == 3) return "Ferret";
  if (species == 4) return "Bird";
  if (species == 5) return "Other";
}

export function getPetStatusNameById(status) {
  if (status == 100) return "Initial";
  if (status == 101) return "For Adoption";
  if (status == 102) return "For Sale";
  if (status == 103) return "Hospitalized";
  if (status == 104) return "In Boarding";
  if (status == 105) return "In Transit";
  if (status == 106) return "Transfert Pending";
  if (status == 107) return "Ownership Changed (external)";
  if (status == 108) return "Lost";
  if (status == 109) return "Found";
  if (status == 110) return "Deceased";
}

export async function petOfUser() {
  let contacts = [];

  const { data } = await petsByUser(localStorage.getItem("email"));
  console.log(data);
  const coowned_pets = data.coowned_pets;
  const owned_pets = data.owned_pets;
  const pets_shared_with_me = data.pets_shared_with_me;

  coowned_pets.forEach(async (item) => {
    if (item && item.petID) {
      const { data } = await petRecordById(item.petID);
      const petRecord = data.petRecord;
      petRecord["checked"] = false;
      contacts.push(petRecord);
    }
  });

  owned_pets.map(async (item) => {
    if (item && item.petID) {
      const { data } = await petRecordById(item.petID);
      const petRecord = data.petRecord;
      petRecord["checked"] = false;
      contacts.push(petRecord);
    }
  });

  pets_shared_with_me.forEach(async (item) => {
    if (item && item.petID) {
      const { data } = await petRecordById(item.petID);
      const petRecord = data.petRecord;
      petRecord["checked"] = false;
      contacts.push(petRecord);
    }
  });
}

/*  
34, 37, 40, 31, 28, 25, undefined, 19, 16, 12, 8, 3*/

export function examresult(item) {
  if (item == "HD - A") return 1;
  if (item == "HD - B") return 2;
  if (item == "HD - C") return 3;
  if (item == "HD - D") return 4;
  if (item == "HD - E") return 5;
  if (item == "ED - 0") return 6;
  if (item == "ED - 1") return 7;
  if (item == "ED - 2") return 8;
  if (item == "ED - 3") return 9;
  if (item == "PHTVL/PHPV - 0") return 10;
  if (item == "PHTVL/PHPV - doubt") return 11;
  if (item == "PHTVL/PHPV - 1") return 12;
  if (item == "PHTVL/PHPV - 2+") return 13;
  if (item == "VWD - Clear") return 14;
  if (item == "VWD - Carrier") return 15;
  if (item == "VWD - Affected") return 16;
  if (item == "Dilution - DD") return 17; //---
  if (item == "Dilution - Dd") return 18;
  if (item == "Dilution - dd") return 19;
  if (item == "DCM1 -/-") return 20; //--
  if (item == "DCM1 +/-") return 21;
  if (item == "DCM1 +/+") return 22;
  if (item == "DCM2 -/-") return 23; //--
  if (item == "DCM2 +/-") return 24;
  if (item == "DCM2 +/+") return 25;
  if (item == "DINGS - Clear") return 26;
  if (item == "DINGS - Carrier") return 27;
  if (item == "DINGS - Affected") return 28;
  if (item == "CF5 - wildtype") return 29;
  if (item == "CF5 +/-") return 30;
  if (item == "CF5 +/+") return 31;
  if (item == "Holter - Normal") return 32;
  if (item == "Holter - Equivocal") return 33;
  if (item == "Holter - Abnormal") return 34;
  if (item == "Echo - Normal") return 35;
  if (item == "Echo - Equivocal") return 36;
  if (item == "Echo - Abnormal") return 37;
  if (item == "Thyroid - Normal") return 38;
  if (item == "Thyroid - Equivocal") return 39;
  if (item == "Thyroid - Abnormal") return 40;
}

export function validEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function validPhone(phone) {
  var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{0,7}$/;

  return re.test(phone);
}
