import http from "./httpService";
import { apiUrl } from "../config.json";

export function existEmailPhoneNumber(email, phonenumber) {
  return http.get(apiUrl + "authentication/noSocial/existEmailPhoneNumber", {
    params: {
      email_address: email,
      phone_number: phonenumber,
    },
  });
}

export function existSocialEmail (email, provider) {
  return http.get(apiUrl + "authentication/social/existSocialEmail", {
    params: {
      email_address: email,
      provider: provider,
    },
  });
}
export function checkSocialPhoneNumber(phone_number) {
  return http.get(apiUrl + "social/existSocialPhoneNumber", {
    params: {
      phone_number: phone_number,
    },
  });
}

export function getEmailSocialRegistration(code) {
  return http.get(apiUrl + "authentication/" + code);
}
