import { login } from '../services/authService';
import { getuser, saveUser } from '../services/userService';
import { saveDogBreeder, updateDogBreeder } from '../services/dogBreedersService';
import { updatePetOwner } from '../services/petOwnerServices';
import { updateGuest } from '../services/guestService';
import router from '../router';
import moment from 'moment';

export default {
  namespaced: true,

  state: {
    token: null,
    user: null,
    resUser: '',
    role: null,
  },

  getters: {
    authenticated(state) {
      console.log('state', state);
      return state.token != null && state.user != null;
    },

    user(state) {
      return state.user;
    },

    resUser(state) {
      return state.resUser;
    },

    role(state) {
      return state.role;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_USER(state, user) {
      state.user = user;
    },

    SET_RESPONSE_USER(state, resUser) {
      state.resUser = resUser;
    },

    SET_ROLE(state, role) {
      state.role = role;
    },
  },
  actions: {
    async getUser({ commit, state }, data) {
      console.log(',,,,,,', data);
      await getuser(data.email, data.role)
        .then((response) => {
          console.log(response);
          response.data.kennelFoundationDate
            ? (response.data.kennelFoundationDate = moment(String(response.data.kennelFoundationDate)).format(
                'YYYY-MM-DD'
              ))
            : null;
          response.data.country ? (response.data.country = response.data.country.id) : '';
          response.data.kennelClub ? (response.data.kennelClub = response.data.kennelClub.id) : '';
          commit('SET_USER', response.data);
          commit('accountState', response.data, { root: true });
          //  console.log(state.accountState);
        })
        .catch((error) => {
          commit('SET_USER', null);
          commit('SET_ROLE', null);
          commit('SET_ROLE', null);
        });
    },

    async signInAfterLogin({ commit, dispatch }, data) {
      console.log(',,,,,,signInAfterLogin', data);
      commit('errorForm', '', { root: true });
      console.log('hhhhhhhhhhhhhhhhh');
      try {
        const user_ = data.user;
        const email = user_.email || localStorage.getItem('email');
        const role = user_.groups[0] || localStorage.getItem('role');
        const tokens = data.tokens;
        const token = tokens.access;

        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('role', role);
      } catch (error) {
        console.log(error);
      }
      let storeToken = localStorage.getItem('token');
      let storeEmail = localStorage.getItem('email');
      let storeRole = localStorage.getItem('role');

      dispatch('attempt', { storeToken, storeEmail, storeRole });
    },

    async saveDogBreederLogin({ dispatch }, account) {
      console.log(',,,,,,saveDogBreederLogin', account);
      await saveDogBreeder(account).then((result) => {
        console.log('********* : ', result);
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('role');

        let data = result.data.login_data;
        const user_ = data.user;
        const email = user_.email || localStorage.getItem('email');
        const role = user_.groups[0] || localStorage.getItem('role');
        const tokens = data.tokens;
        const token = tokens.access;

        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('role', role);

        router.push('/onboardingdashboard');
      });

      let storeToken = localStorage.getItem('token');
      let storeEmail = localStorage.getItem('email');
      let storeRole = localStorage.getItem('role');
      dispatch('attempt', { storeToken, storeEmail, storeRole });
    },

    async saveUserLogin({ dispatch, commit }, account) {
      await saveUser(account).then((result) => {
        console.log('********* : ', result);
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('role');

        let data = result.data.login_data;
        console.log(data);
        const user_ = data.user;
        const email = user_.email || localStorage.getItem('email');
        const role = user_.groups[0] || localStorage.getItem('role');
        const tokens = data.tokens;
        const token = tokens.access;

        localStorage.setItem('token', token);
        localStorage.setItem('email', email);
        localStorage.setItem('role', role);
        if (role == 2) {
          router.push('/guestpetoverview');
        } else router.push('/onboardingdashboard');
      });

      let storeToken = localStorage.getItem('token');
      let storeEmail = localStorage.getItem('email');
      let storeRole = localStorage.getItem('role');

      dispatch('attempt', { storeToken, storeEmail, storeRole });
    },

    async updatePetOwner({ commit }, data) {
      console.log(',,,,,,updatePetOwner', data);
      await updatePetOwner(data.image, data.account, data.coordinates).then((response) => {
        console.log(response.data);
        commit('SET_RESPONSE_USER', response);
        commit('SET_USER', response.data);
        commit('editable', false, { root: true });
      });
    },
    async updateGuest({ commit }, data) {
      await updateGuest(data.image, data.account, data.coordinates).then((response) => {
        console.log(response.data);
        commit('SET_RESPONSE_USER', response);
        commit('SET_USER', response.data);
        commit('editable', false, { root: true });
      });
    },
    async updateDogBreeder({ commit }, data) {
      console.log(',,,,,,updateDogBreeder', data);
      await updateDogBreeder(data.image, data.account, data.coordinates).then((response) => {
        console.log(response.data);
        response.data.kennelFoundationDate = moment(String(response.data.kennelFoundationDate)).format('YYYY-MM-DD');
        commit('SET_USER', response.data);
        commit('editable', false, { root: true });
      });
    },
    async signIn({ dispatch }, code) {
      console.log(',,,,,,signIn', code);
      if (code != 'news') {
        try {
          const { data } = await login(code);
          console.log(data);
          const user_ = data.user;
          const email = user_.email || localStorage.getItem('email');
          const role = user_.groups[0] || localStorage.getItem('role');
          const tokens = data.tokens;
          const token = tokens.access;

          localStorage.setItem('token', token);
          localStorage.setItem('email', email);
          localStorage.setItem('role', role);
          console.log(email);
        } catch (error) {
          console.log(error);
        }
        let storeToken = localStorage.getItem('token');
        let storeEmail = localStorage.getItem('email');
        let storeRole = localStorage.getItem('role');
        console.log('signIn----');
        dispatch('attempt', { storeToken, storeEmail, storeRole });
      }
    },

    async signInSP({ dispatch }, code) {
      console.log(',,,,,,signInSP', code);
      if (code != 'news') {
        try {
          const { data } = await loginSP(code);
          const user_ = data.user;
          const email = user_.email || localStorage.getItem('email');
          const role = user_.groups[0] || localStorage.getItem('role');
          const tokens = data.tokens;
          const token = tokens.access;

          localStorage.setItem('token', token);
          localStorage.setItem('email', email);
          localStorage.setItem('role', role);
          console.log(email);
        } catch (error) {
          console.log(error);
        }
        let storeToken = localStorage.getItem('token');
        let storeEmail = localStorage.getItem('email');
        let storeRole = localStorage.getItem('role');
        dispatch('attempt', { storeToken, storeEmail, storeRole });
      }
    },

    async attempt({ commit }, a) {
      commit('SET_TOKEN', a.storeToken);
      commit('SET_ROLE', a.storeRole);

      try {
        console.log('*******//', a);

        const { data } = await getuser(a.storeEmail, a.storeRole);
        commit('SET_USER', data);
      } catch (error) {
        commit('SET_USER', null);
        commit('SET_ROLE', null);
        commit('SET_ROLE', null);
      }
    },
  },
};
