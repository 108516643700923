import http from './httpService';
import { apiUrl } from '../config.json';
import axios from 'axios';

const apiEndpoint = apiUrl + 'petOwners/';

function petOwnersUrl(id) {
  return `${apiEndpoint}petOwner/${id}`;
}

export function getPetOwner(id) {
  return http.get(`${apiEndpoint}petOwner/`, { params: { id: id } });
}

export function savePetOwner(data) {
  const formData = new FormData();
  console.log(data);
  data.image ? formData.append('profileImage', data.image) : '';
  data.provider ? formData.append('provider', data.provider) : formData.append('provider', 'email');
  formData.append('firstName', data.firstName);
  formData.append('lastName', data.lastName);
  formData.append('streetAddress', data.streetAddress);
  formData.append('country', data.country);
  formData.append('postalCode', data.postalCode);
  formData.append('emailAddress', data.email_address);
  formData.append('phoneNo', data.phone_number);
  formData.append('zone_long', data.coordinates.lng);
  formData.append('zone_lat', data.coordinates.lat);
  formData.append('news_updates', data.checkboxNews);
  data.referral != '' ? formData.append('referral', data.referral) : '';
  return axios.post(`${apiEndpoint}petOwner/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updatePetOwner(profileImage, data, coordinates) {
  console.log(coordinates);
  const formData = new FormData();
  profileImage ? formData.append('profileImage', profileImage) : null;
  formData.append('firstName', data.firstName);
  formData.append('zone_long', coordinates.lng);
  formData.append('zone_lat', coordinates.lat);
  formData.append('lastName', data.lastName);
  formData.append('streetAddress', data.streetAddress);
  formData.append('country', data.country);
  formData.append('postalCode', data.postalCode);
  formData.append('emailAddress', data.emailAddress);
  formData.append('phoneNo', data.phoneNo);
  return http.put(petOwnersUrl(data.emailAddress), formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deletePetOwner(id) {
  return http.delete(petOwnersUrl(id));
}
