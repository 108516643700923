<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  // Your component configuration here

  mounted() {
    // This code will run after the component is mounted to the DOM
    this.issueCollector();
  },

  methods: {
    async issueCollector() {
      // Load and execute external JavaScript files here
      const script = document.createElement("script");
      /*  script.src =
        "https://team-1633882512564.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=fbd1facf"; */
      const { default: collectorScript } = await import(
        "./jiraissuecollector.js"
      );

      if (collectorScript == {}) {
        // Create a script element and set its source
        script.src = collectorScript;
        script.type = "text/javascript";
        document.head.appendChild(script);

        script.onload = () => {
          console.log("issueCollector loaded and executed.");
          // You can perform any necessary actions after the script is loaded here
        };
      } else {
        // console.error("Collector script is not an object:", collectorScript);
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
