import {
  getDogBreederProfile,
  getBreeder,
  getDogBreeder,
  updateDogBreederProfile,
  getSectionsByGroups,
  getBreederProfileByQr,
  getBreederByWB,
  getBreederBySB,
} from "../services/breederDirectoryService";

const state = {
  breeders: {},
  groupsSections: [],
  filterGroupsSections: [],
  breeds: [],
  filtredBreeds: [],
  error: "",
};

const getters = {
  getBreeders(state) {
    return state.breeders;
  },
  getGroupsSections(state, vall) {
    console.log(vall);
    if (vall == "") {
      return state.groupsSections;
    } else {
      return state.filterGroupsSections.length ? state.filterGroupsSections : state.groupsSections;
    }
  },
  getBreeds(state, val) {
    if (val == "") {
      return state.breeds;
    } else {
      console.log(state.filtredBreeds);
      return state.filtredBreeds.length ? state.filtredBreeds : state.breeds;
    }
  },

  getfiltredBreeds(state) {
    return state.filtredBreeds;
  },
  getfiltergroupsSections(state) {
    return state.filterGroupsSections;
  },
  getError(state) {
    return state.error;
  },
};

const mutations = {
  setBreeders(state, breeders) {
    state.breeders = breeders;
  },
  setfiltredBreeds(state, filtredBreeds) {
    state.filtredBreeds = filtredBreeds;
  },
  newBreeders(state, breeder) {
    state.breeders = [...state.breeders, breeder];
  },
  updBreeders(state, updatedBreeders) {
    state.breeders = updatedBreeders;
  },
  deleteBreeder(state, data) {
    const index = state.breeders.indexOf(data);
    state.breeders.splice(index, 1);
  },
  setGroupsSections(state, groupsSections) {
    state.groupsSections = groupsSections;
  },
  setFilterGroupsSections(state, filterGroupsSections) {
    state.filterGroupsSections = filterGroupsSections;
  },
  setBreeds(state, breeds) {
    function compareBreeds(a, breeds) {
      if (a.dist_in_km < breeds.dist_in_km) return -1;
      if (a.dist_in_km > breeds.dist_in_km) return 1;
      return 0;
    }
    state.breeds = breeds.sort(compareBreeds);
  },
  error(state, data) {
    return (state.error = data);
  },
};

const actions = {
  async getBreeders({ commit }, id) {
    const response = await getDogBreederProfile(id);
    console.log(response.data);
    commit("setBreeders", response.data);
  },

  /* async addAppointment({ commit }, appointment) {
    const response = await addAppointment(appointment);
    commit("newAppointment", response.data);
  },*/

  async updatedBreeders({ commit }, breeder) {
    await getDogBreeder(localStorage.getItem("email"))
      .then(async (r) => {
        const response = await updateDogBreederProfile(breeder, r.data);
        commit("updBreeders", response.data);
        commit("manageBreederModals", false, { root: true });
      })
      .catch((error) => {
        if (error && error.response) {
          commit("error", "API internal error.");
        }
      });
  },

  async getGroupsSections({ commit }) {
    await getSectionsByGroups()
      .then((response) => {
        console.log(response.data);
        commit("setGroupsSections", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async filterGroupsSections({ commit }, val) {
    try {
      if (val == "") {
        //do nothing
        commit("setFilterGroupsSections", []);
      } else {
        /*
         */
        console.log(state.groupsSections);
        const result = state.groupsSections.filter((e) => (e.group ? e.group.toLowerCase().trim().includes(val.toLowerCase().trim()) : null));

        /*   state.groupsSections.map((sections) => {
          sections.sections.filter((e) => {
            console.log(e.section);
            e.section ? e.section.toLowerCase().trim().includes(val.toLowerCase().trim()) : null;
          });
        }); */
        // console.log(result);
        commit("setFilterGroupsSections", result);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getBreeds({ commit }, breed_name) {
    await getBreeder(breed_name)
      .then((response) => {
        commit("setBreeds", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async filterBreedsByCodpostal({ commit, dispatch }, val) {
    try {
      if (val == "") {
        //do nothing
        commit("setfiltredBreeds", []);
      } else {
        const result = state.breeds.filter((e) => (e.profile_postalCode ? e.profile_postalCode.includes(val) : null));
        console.log(result);
        commit("setfiltredBreeds", result);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async filterBreeds({ commit, dispatch }, val) {
    try {
      if (val == "") {
        //do nothing
        commit("setfiltredBreeds", []);
      } else {
        const result = state.breeds.filter((e) => (e.kennelName ? e.kennelName.toLowerCase().trim().includes(val.toLowerCase().trim()) : null));
        console.log(result);
        commit("setfiltredBreeds", result);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async filterWorkingBreeder({ commit }, val) {
    await getBreederByWB(val)
      .then((response) => {
        commit("setBreeds", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async filterShowBreeder({ commit }, val) {
    await getBreederBySB(val)
      .then((response) => {
        commit("setBreeds", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
